.wrapper-footer {
	padding-bottom: 1.1875rem;
	padding-top: 1.1875rem;
	position: relative;
}

.phone,
.email {
	@include txt-font-16($white);
	font-weight: 800;
	letter-spacing: -0.44px;
	line-height: 20px;
}

.copyright {
	border-top: 1px solid $links;
	padding-top: 0.5rem;

	p {
		@include txt-font-12($white, 800);
		font-weight: 500;
		letter-spacing: -0.33px;
		line-height: 14px;
		margin: 0;

		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			padding: 0.5rem;
			text-align: center;
		}
	}
}

.footer-logo {
	display: none;
}
.foot-firt-trg {
	margin-top: 3rem;
	.foot-line-twoe {
		display: flex;
		justify-content: space-between;
		p {
			margin: 0;
			color: $white;
			span {
				color: $main-yellow;
			}
		}
		a {
			color: $white;
			text-transform: uppercase;
		}
		span.yellow-line {
			color: $main-yellow;
			margin: 0 0.3rem;
		}
	}
}
.footer-logo-link {
	height: 6rem;
	width: 14rem;
	width: 113px;
}

.footer-sm {
	.ico {
		text-align: center;
		color: $white;
		margin: 0;
		height: auto;
		&::before {
			padding: 10px;
			font-size: 18px !important;
			color: $white;
			background: transparent;
			margin: 0 0.5rem;
			border: 2px solid $white;
		}
		&:hover {
			&::before {
				color: $main-yellow;
				border: 2px solid $white;
			}
		}
	}

	&:last-of-type {
		margin-right: 0;
	}
}
.social-icons {
	text-align: left;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	margin-top: 2rem;
}

.f-derechos {
	margin-top: 0;
	padding-left: 1rem;
}

footer {
	.foot-line-one {
		display: flex;
		column-gap: 2rem;
		p {
			margin: 0;
			font-size: 12px !important;
		}
	}
	.p-social {
		&:hover {
			color: $black;
		}
	}
	.footer-sm:hover {
		.p-social {
			color: $black;
		}
	}
	&:before,
	&:after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: 467px;
		height: 270px;
		background-size: 146px 90px;
		background-position: 0px 169px;
		background-repeat: repeat-x;
		z-index: -1;
	}
	&:after {
		background-size: 146px 90px;
		background-position: 73px -64px;
		background-repeat: repeat-x;
		bottom: 264px;
		z-index: -1;
	}
}

footer {
	padding-top: 3rem;
}

.footer {
	position: relative;
	background: black;

	.row {
		justify-content: space-around;
		align-items: center;

		@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
			align-items: center;
			flex-direction: column;
			justify-content: center;
		}
	}
	.footer-grid {
		display: flex;
		column-gap: 2rem;
		div {
			flex: 1;
			h2 {
				font-size: 19px;
				color: $main-yellow;
				font-weight: 800 !important;
			}
			p {
				color: $white;
				font-size: 15px;
				min-height: 180px;
				text-align: justify;
				span {
					color: $main-yellow;
				}
			}
			a {
				color: $white;
				&:hover{
					color: $main-yellow;
				}
			}
		}
	}
}

.contact-f {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
		flex-direction: column;
	}
}

.footer-sm-fb {
	&.ico {
		color: $black;
		border: 2px solid #000;
		margin-right: 0.5rem;
	}
}

.footer-sm-tw {
	&.ico {
		color: $black;
		border: 2px solid #000;
		margin-right: 0.5rem;
	}
}

.footer-sm-in {
	&.ico {
		color: $black;
		border: 2px solid #000;
		margin-right: 0.5rem;
	}
}

.footer-sm-ins {
	&.ico {
		color: $black;
		border: 2px solid #000;
		margin-right: 0.5rem;
	}
}

.footer-sm-yt {
	&.ico {
		color: #ed1c24;
	}
}

.link-privacidad {
	margin-right: 4rem;
	line-height: 40px;
	letter-spacing: -0.33px;

	@media all and (max-width: map-get($grid-breakpoints, "md") + 1) {
		margin-left: auto;
		margin: 0 auto 1rem;
		display: block;
	}

	&:hover {
		line-height: 40px;
	}
	&:last-of-type {
		margin-right: 0;
	}
}

// // ******************
// // contacto
// // ******************

.onload {
	.contact-form {
		&::after {
			content: " ";
			background: url(#{$_image-path}/bkg/ajax-loader.gif) no-repeat center center;
			display: block;
			height: 40px;
			width: 40px;
		}
	}
}

.content-form {
	padding: 3rem 0;
	display: flex;
	column-gap: 4rem;

	textarea {
		height: 4.8125rem;
	}
}

.msg-succes {
	@include txt-font-28($color-primary, 800);
	font-weight: 800;
	letter-spacing: -0.5px;
	margin: 2rem auto;
	text-align: center;
	bottom: 0;
	border: 1px solid $color-primary;
	padding: 1rem;
}

.title-contact {
	@include txt-font-50($brand-primary);
	font-weight: 900;
	letter-spacing: -1.39px;
	line-height: 63px;
	margin: auto;
	padding: 0.7rem 0;
	text-transform: uppercase;
	word-break: break-all;

	&::before {
		background: $lines-titles-2;
		content: " ";
		display: block;
		height: 8px;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 75px;
	}

	&::after {
		background: $lines-titles-2;
		bottom: 0;
		content: " ";
		display: block;
		height: 8px;
		left: 0;
		position: absolute;
		right: 0;
		width: 75px;
	}
}

.subtitle-contact {
	@include txt-font-28($white, 800);
	font-weight: 800;
	letter-spacing: -0.75px;
	margin: 2.3rem auto 0.5rem auto;
	position: relative;
	text-transform: uppercase;
	z-index: 10;
}

.text-contact {
	text-align: center;
	@include txt-font-50($color-primary, 800);
	font-weight: 800;
	letter-spacing: -0.5px;
	margin: 0 auto 1.2rem;
}

.contact-top {
	font-weight: normal;
	color: $black;
	flex: 1;
	p {
		font-size: 16px;
		margin: 0;
	}
	h2 {
		font-size: 20px !important;
	}
	.caption-contact {
		font-size: 20px;
	}
}

.contact-form {
	flex: 1;
	@media all and (min-width: map-get($grid-breakpoints, "md") + 1) {
		width: 40%;
	}

	@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
		width: 358px;
		margin-left: auto;
		margin-right: auto;
	}

	@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
		width: 100%;
	}

	input:not([type="radio"]):not([type="checkbox"]),
	select {
		border-bottom: 2px solid $black;
	}

	input:not([type="radio"]):not([type="checkbox"]),
	select,
	textarea {
		margin-bottom: 2rem;
	}
	input:not([type="radio"]):not([type="checkbox"]),
	select,
	textarea {
		opacity: 0.7;
		color: $black;
		&:focus {
			outline: none;
			background: transparent;
			color: $black;
		}
	}
	textarea {
		border: 2px solid $black;
		min-height: 207px;
		&:focus {
			outline: none;
		}
	}
	input:placeholder-shown:not(:focus) + label {
		margin-left: 10px;
		color: $black;
	}
	textarea:placeholder-shown:not(:focus) + label {
		margin-left: 10px;
		color: $black;
	}
}

.form-content {
	order: 2;
}

.mapouter,
.gmap_canvas,
#gmap_canvas {
	width: 100%;
}

.sec-contacto {
	position: relative;

	.row {
		display: flex;
		justify-content: flex-start !important;
		.form-field {
			padding: 0 !important;
		}
	}

	.form-content {
		opacity: 1;
		background: transparent;
		padding: 0;
	}

	input:not([type="radio"]):not([type="checkbox"]) + label,
	select + label,
	textarea + label {
		top: -1.5rem;
		color: $black !important;
		font-family: "Montserrat", sans-serif;
		margin-left: 0;
	}

	input:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus) + label,
	select.invalid:not(:focus) + label,
	select:invalid:not(:focus) + label,
	textarea:placeholder-shown:not(:focus) + label {
		
		font-size: 20px;
	}

	input:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus) + label,
	textarea:placeholder-shown:not(:focus) + label {
		font-weight: 100;
	}

	textarea,
	input,
	label {
		font-family: $font-family-base;
		font-weight: 500;
		letter-spacing: -0.19px;
		line-height: 24px;
	}

	.buttons {
		.button {
			padding: 10px 50px;
			color: $white;
			font-size: 18px;
			background: $black;
			&:hover {
				background: transparent;
				border: 2px solid $black;
				color: $black;
			}
		}
	}
}

input[type="checkbox"] + label,
input[type="radio"] + label {
	color: $black;
	font-size: 16px;
}

.sec-contacto .form-content {
	display: flex;
  }

.contact-background {
	box-shadow: 0px 90px 32px 0px rgba(0, 0, 0, 0.61) inset;
	-webkit-box-shadow: 0px 90px 32px 0px rgba(0, 0, 0, 0.61) inset;
	-moz-box-shadow: 0px 90px 32px 0px rgba(0, 0, 0, 0.61) inset;
	position: absolute;
	width: 100%;
	height: 125px;
	z-index: 555;
}
.contact-top {
	@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
		width: 100%;
	}
}

.form-field.sectitle:nth-child(4n) {
	color: #000c56;
	font-weight: 800;
	text-transform: uppercase;
	margin-top: 40px;
}

.form-field.sectitle:nth-child(5n) {
	margin-bottom: 30px;
}
.sec-cont {
	.sec-contact-top {
		background-size: cover;
		min-height: 400px;
		.container-fluid {
			max-width: 100%;
		}
	}
}

.header {
	background: transparent;
	height: auto;
	padding: 0;
	z-index: 888;
	position: absolute;
	width: 100%;

	.footer-sm {
		margin-left: 1rem;

		@media all and (max-width: map-get($grid-breakpoints, "md") + 1) {
			margin-right: 4rem;
			text-align: center;
		}

		.ico {
			@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
				height: 25px;
				width: 25px;

				&::before {
					position: relative;
				}
			}
		}
	}

	.footer-sm-fb::before {
		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			font-size: 1.2rem;
			top: -0.2rem;
		}
	}

	.footer-sm-tw::before {
		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			font-size: 1.2rem;
			top: -0.1rem;
		}
	}

	.footer-sm-in::before {
		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			font-size: 1.4rem;
			top: -0.2rem;
		}
	}

	.container-fluid {
		min-height: 73px;
		z-index: 10;
		border-bottom: 2px solid $black;
	}

	@media all and (max-width: map-get($grid-breakpoints, "md") + 1) {
		#mnu-Search {
			margin: auto;
		}
	}

	.mnu {
		padding: 1rem 0;
		border-right: 2px solid $black;
		margin-right: 1.5rem;
		&:last-of-type {
			border-right: none;
			margin-right: 0;
		}
		a {
			font-size: 14px;
			font-weight: 500;
			text-transform: uppercase;
			color: $white;
			letter-spacing: 0.49px;
			padding-right: 1.5rem;
			font-family: "Montserrat", sans-serif;

			@media all and (max-width: map-get($grid-breakpoints, "md") + 1) {
				padding: 0.5rem 0;
				color: $white;
				&.active {
					color: #aaa !important;
					font-weight: 600;
				}
			}

			&:hover,
			&.active {
				color: $main-yellow;
			}
		}
		&:last-child {
			a {
				padding-right: 0;
			}
		}
	}

	#mnu-Search {
		background: url(#{$_image-path}/bkg/ofera-lupa.png) no-repeat right center;
		width: 30px;
		height: auto;
		a {
			font-size: 0;
		}
	}

	.site-logo {
		align-items: center;
		border: 0;
		display: flex;
		font-size: 0;
		justify-content: center;
		width: 13%;
		margin-right: 2rem;

		@media all and (max-width: map-get($grid-breakpoints, "md") + 1) {
			width: 40%;
		}

		&:hover {
			color: $white;
			transition: none;
		}
	}

	.site-logo-link {
		border: 0;
		display: block;
		font-size: 0;
		width: 139px;
		height: 35px;
		padding: 0;
		background-position: left;
	}

	.main-menu {
		display: flex;
		flex: 1 1;
		justify-content: flex-end;
		width: 30.6875rem;

		@media all and (max-width: map-get($grid-breakpoints, "md") + 1) {
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

		.menu-content {
			transition: all 0.55s ease-in-out;
			z-index: 5;

			@media all and (max-width: map-get($grid-breakpoints, "md") + 1) {
				left: -100vh;
			}
		}

		&.active {
			.menu-content {
				background: $black;
				left: 0;
				opacity: 1;
				pointer-events: all;
				text-align: center;
				top: 4.6rem;
				transform: translate(0, 0);
				width: 100%;
			}

			.menu-mobile {
				background: url(#{$_image-path}/bkg/menu.png) no-repeat center;
				background-size: contain;
			}
		}
	}
	// mainmenu
}

.menu-mobile {
	background: url(#{$_image-path}/bkg/menu.png) no-repeat center;
	background-size: contain;
	margin-top: 0 !important;
}

.header-bntns {
	display: flex;
	column-gap: 1rem;
}

.subheader {
	height: 32px;
	background: $black;
	.content {
		margin: auto;
		display: flex;
		justify-content: flex-end;
		max-width: 1100px;
		a {
			font-size: 14px;
			color: $white;
			text-transform: uppercase;
			border-right: 1px solid $main-yellow;
			margin-right: 0.5rem;
			padding-right: 0.5rem;
			&:last-of-type {
				border-right: none;
				margin-right: 0;
				padding-right: 0;
			}
			&:hover {
				text-decoration: none;
			}
		}
	}
}

.menu-mobile {
	cursor: pointer;
	color: $black;
	margin-top: 2.5rem;
	padding: 0;
	position: absolute;
	right: 2rem;
	text-align: center;
	top: 1rem;
	width: 2.5rem;
	height: 2.5rem;
	&::before {
		display: none;
		padding: 0;
		font-size: 0;

		@media all and (max-width: map-get($grid-breakpoints, "md") + 1) {
			display: block;
		}
	}
}

@charset "UTF-8";
/*! by SalsaLab */
/*
 * SCSS DEFAULT VARIABLEs File
 * by SalsaLab
 */
/*
 * DEBUG
 * ****************************************
 */
/*
 * BOOTSTRAP GRID VARS
 * ****************************************
 */
/*
 * COLORS
 * ****************************************
 */
/*
 * FONTS
 * ****************************************
 */
/*
 * HTML ELEMENTS
 * ****************************************
 */
/*
 * INCLUDES
 * ****************************************
 */
/*
 * MISC
 * ****************************************
 */
/*
 * SCSS THEME VARIABLEs File
 * by SalsaLab
 */
/*
 * DEBUG
 * ****************************************
 */
/*
 * BOOTSRAP GRID
 * ****************************************
 */
/*
 * COLORS
 * ****************************************
 */
/*
 * FONTS
 * ****************************************
 */
/*
	* FONTS
	* ****************************************
	*/
/*
 * MISC
 * ****************************************
 */
/*
 * HTML ELEMENTS
 * ****************************************
 */
/*
 * LAYOUT
 * ****************************************
 */
/*
 * PATTERNS
 * ****************************************
 */
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1248px) {
    .container {
      max-width: 1248px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1248px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1248px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1248px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

/**
 * SCSS Basic File
 * by SalsaLab
 */
@font-face {
  font-family: "iconfont";
  src: url("../fonts/iconfont.eot");
  src: url("../fonts/iconfont.eot?#iefix") format("embedded-opentype"), url("../fonts/iconfont.woff2") format("woff2"), url("../fonts/iconfont.woff") format("woff"), url("../fonts/iconfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@-ms-viewport {
  width: device-width; }

html {
  font-size: 62.5%;
  line-height: 1.5;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  background: #fff;
  box-sizing: border-box;
  color: #67ace8;
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  -ms-overflow-style: scrollbar;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0; }
  body > * {
    font-size: 16px; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container-fluid {
  margin: 0 auto;
  max-width: 1248px; }

/*
 * SCSS BASIC: BLOCK Tags file
 * by SalsaLab
 */
p, ul,
ol {
  margin: 1em auto; }

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.33333;
  margin: 1em auto; }

h1 {
  font-size: 3.78975rem; }

h2 {
  font-size: 3.78975rem; }

h3 {
  font-size: 2.84302rem; }

h4 {
  font-size: 2.84302rem; }

h5 {
  font-size: 2.1328rem; }

h6 {
  font-size: 2.84302rem; }

img,
svg {
  display: inline-block;
  height: auto;
  max-width: 100%;
  vertical-align: baseline; }

img,
iframe {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

audio:not([controls]) {
  display: none;
  height: 0; }

ul,
ol {
  padding-left: 2em; }

li {
  list-style: none;
  position: relative; }
  li::before {
    color: #0275d8;
    left: -2em;
    position: absolute;
    top: 0; }

ol {
  counter-reset: bullet; }

ol > li::before {
  content: counter(bullet) ".";
  counter-increment: bullet; }

ul > li::before {
  content: "•"; }

nav ul,
nav ol {
  margin: 0;
  padding: 0; }

nav li::before {
  content: none; }

/*
 * SCSS BASIC: INLINE Tags file
 * by SalsaLab
 */
a {
  color: #0275d8;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-in-out; }
  a:active, a:hover {
    color: #014c8c;
    outline: 0;
    text-decoration: underline; }

/*
 * SCSS BASIC: FORMS Tags file
 * by SalsaLab
 */
.form-field {
  position: relative; }

.form-content,
.buttons {
  padding: 0.5rem 0;
  position: relative; }

label {
  display: inline-block;
  font: inherit; }
  label:empty {
    display: none; }

textarea:placeholder-shown:not(:focus) + label,
input:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus) + label, select:invalid:not(:focus) + label,
select.invalid:not(:focus) + label {
  color: #000;
  font-size: 1rem;
  line-height: 1rem;
  padding: 0.6rem 0.8rem;
  pointer-events: none;
  top: 0.5rem; }

select,
textarea,
input:not([type="radio"]):not([type="checkbox"]) {
  background: none;
  border: none;
  border-bottom: solid 1px #1f1d64;
  color: #faf5e6;
  font-family: inherit;
  font-size: 1rem;
  height: 2.7rem;
  line-height: 1.5rem;
  padding: 0.6rem 0.8rem;
  transition: all 0.2s ease-in-out;
  width: 100%; }
  select + label,
  textarea + label,
  input:not([type="radio"]):not([type="checkbox"]) + label {
    color: #000;
    display: block;
    font-size: 0.75rem;
    left: 0;
    position: absolute;
    top: 0.125rem;
    transition: all 0.2s ease-in-out; }
  select::placeholder,
  textarea::placeholder,
  input:not([type="radio"]):not([type="checkbox"])::placeholder {
    color: #000; }
  select:focus,
  textarea:focus,
  input:not([type="radio"]):not([type="checkbox"]):focus {
    border-bottom: solid 2px #1f1d64;
    box-shadow: none;
    padding-bottom: 0.5rem; }
    select:focus + label,
    textarea:focus + label,
    input:not([type="radio"]):not([type="checkbox"]):focus + label {
      color: #000; }
  select:invalid, select.invalid,
  textarea:invalid,
  textarea.invalid,
  input:not([type="radio"]):not([type="checkbox"]):invalid,
  input:not([type="radio"]):not([type="checkbox"]).invalid {
    box-shadow: none; }
  select[disabled], select[disabled]:placeholder-shown:not(:focus),
  textarea[disabled],
  textarea[disabled]:placeholder-shown:not(:focus),
  input:not([type="radio"]):not([type="checkbox"])[disabled],
  input:not([type="radio"]):not([type="checkbox"])[disabled]:placeholder-shown:not(:focus) {
    border-color: #1f1d64;
    color: #999;
    cursor: default;
    pointer-events: none; }
    select[disabled] + label, select[disabled]:placeholder-shown:not(:focus) + label,
    textarea[disabled] + label,
    textarea[disabled]:placeholder-shown:not(:focus) + label,
    input:not([type="radio"]):not([type="checkbox"])[disabled] + label,
    input:not([type="radio"]):not([type="checkbox"])[disabled]:placeholder-shown:not(:focus) + label {
      color: #999; }

textarea:placeholder-shown:not(:focus)::placeholder,
input:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus)::placeholder {
  color: #000;
  border-bottom-color: #1f1d64;
  opacity: 0; }

textarea:invalid:not(:focus):not(:placeholder-shown), textarea.invalid:not(:focus):not(:placeholder-shown),
input:not([type="radio"]):not([type="checkbox"]):invalid:not(:focus):not(:placeholder-shown),
input:not([type="radio"]):not([type="checkbox"]).invalid:not(:focus):not(:placeholder-shown) {
  border-bottom-color: #d9534f;
  box-shadow: none;
  color: #d9534f; }
  textarea:invalid:not(:focus):not(:placeholder-shown) + label, textarea.invalid:not(:focus):not(:placeholder-shown) + label,
  input:not([type="radio"]):not([type="checkbox"]):invalid:not(:focus):not(:placeholder-shown) + label,
  input:not([type="radio"]):not([type="checkbox"]).invalid:not(:focus):not(:placeholder-shown) + label {
    color: #d9534f; }

input[type="radio"], input[type="checkbox"] {
  margin-right: 1.6rem; }
  input[type="radio"] + label, input[type="checkbox"] + label {
    color: #000; }

select:invalid:not(:focus),
select.invalid:not(:focus) {
  color: rgba(0, 0, 0, 0);
  border-bottom-color: #1f1d64; }

option {
  color: #faf5e6; }
  option[disabled] {
    color: #fcf8ee; }

textarea {
  height: 3em;
  min-height: 3em;
  resize: vertical; }

button,
.button {
  appereance: none;
  background: #0275d8;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  line-height: 1;
  margin-bottom: 16px;
  padding: 0.8rem 1.6em;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out; }
  button + button,
  button + .button,
  .button + button,
  .button + .button {
    margin-left: 16px; }
  button:hover, button:focus, button:active,
  .button:hover,
  .button:focus,
  .button:active {
    background: #0275d8;
    color: #fff;
    text-decoration: none; }
  button.negative,
  .button.negative {
    background: #fff;
    color: #0275d8; }
  button:hover, button:focus,
  .button:hover,
  .button:focus {
    transform: scale(1.05, 1.05); }
  button:active,
  .button:active {
    transform: scale(0.9, 0.9);
    transition: transform 0.1s ease-out; }
  button[disabled],
  .button[disabled] {
    background: #ccc;
    color: #999;
    cursor: default;
    pointer-events: none; }

/**
 * SCSS ICONFONT Styles
 * by SalsaLab
 */
.ico {
  display: inline-block;
  line-height: 1; }
  .ico::before {
    display: inline-block;
    font-family: "iconfont";
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1;
    vertical-align: middle;
    color: inherit;
    content: attr(data-ico);
    padding: 0.75rem; }

.ico-sm::before {
  font-size: 1.6rem;
  padding: 1.6rem; }

.ico-md::before {
  font-size: 4.8rem;
  padding: 2.4rem; }

.ico-lg::before {
  font-size: 6.4rem;
  padding: 3.2rem; }

.ico-inline::before {
  padding: 0 0.8rem 0 0; }

/*
 * LAYOUT: SITE HEADER
 * by SalsaLab
 */
.header {
  height: 9.6rem;
  width: 100%;
  position: relative; }
  @media all and (min-width: 992px) {
    .header {
      height: 6.4rem; } }

.header .container-fluid {
  align-items: center;
  display: flex;
  justify-content: space-between; }

.site-logo-link {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  background: url(../images/yellow-logo.svg) center no-repeat;
  background-size: contain;
  display: block;
  height: 9.6rem;
  width: 12.8rem; }
  @media all and (min-width: 992px) {
    .site-logo-link {
      height: 14rem;
      width: 15.6rem; } }

/*
 * LAYOUT: MENU
 * by SalsaLab
 */
.menu a {
  cursor: pointer;
  display: block;
  text-decoration: none; }

.menu-mobile {
  cursor: pointer; }

@media all and (max-width: 767px) {
  .menu {
    position: relative; }
    .menu.active .menu-content {
      opacity: 1;
      pointer-events: all; }
    .menu a {
      display: block;
      width: 100%; }
  .menu-content {
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 4.8rem;
    transition: opacity 0.15s linear;
    width: 100vw; }
    .menu-content.active {
      display: block;
      opacity: 1; } }

@media all and (min-width: 768px) {
  .menu-mobile {
    display: none; }
  .menu-content {
    display: flex;
    justify-content: flex-start; } }

/*
 * LAYOUT: SITE HEADER
 * by SalsaLab
 */
.footer-logo-link {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  background: url(../images/muve-logo.svg) center no-repeat;
  background-size: contain;
  display: block;
  height: 10rem;
  width: 10rem; }
  @media all and (min-width: 768px) {
    .footer-logo-link {
      height: 10rem;
      width: 10rem; } }

/**
 * Swiper 3.4.2
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 *
 * http://www.idangero.us/swiper/
 *
 * Copyright 2017, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 *
 * Licensed under MIT
 *
 * Released on: March 10, 2017
 */
.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  z-index: 1; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-wrapper {
  box-sizing: content-box;
  display: flex;
  height: 100%;
  position: relative;
  transition-property: transform;
  width: 100%;
  z-index: 1; }

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  box-lines: multiple;
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  margin: 0 auto;
  transition-timing-function: ease-out; }

.swiper-slide {
  height: 100%;
  flex-shrink: 0;
  position: relative;
  width: 100%; }
  .swiper-slide .container {
    position: relative;
    z-index: 3; }
  .swiper-slide .banner {
    position: absolute;
    z-index: 2; }

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto; }

.swiper-container-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height; }

.swiper-button-prev,
.swiper-button-next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10; }

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  cursor: default;
  opacity: 0.35;
  pointer-events: none; }

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 0;
  right: auto; }

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  left: auto;
  right: 0; }

.swiper-pagination {
  bottom: 0;
  left: 50%;
  position: absolute;
  text-align: center;
  transition: 300ms;
  transform: translateX(-50%);
  z-index: 10; }

.swiper-pagination-bullet {
  background: rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  border: solid 1px rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  display: inline-block;
  height: 1rem;
  line-height: 1;
  margin: 1.1rem 0.7rem;
  width: 1rem; }

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer; }

.swiper-pagination-bullet-active {
  background: #00e6e0;
  background-clip: padding-box; }

.swiper-slide {
  overflow: hidden; }
  .swiper-slide .banner {
    height: auto;
    left: 50%;
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto; }

/**
 * NAVDROP styles
 * by SalsaLab
 */
.navdrop-drop {
  background: #0275d8;
  border-radius: 1.09375rem;
  color: #fff;
  cursor: pointer;
  padding: 0.8rem 1.6em;
  position: relative;
  z-index: 6; }
  @media all and (min-width: 768px) {
    .navdrop-drop {
      display: none; } }

.navdrop-title {
  display: block;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis; }

.navdrop-closed,
.navdrop-opened {
  position: absolute;
  right: 0.4rem;
  top: 50%;
  transform: translateY(-50%); }

.navdrop-opened {
  display: none; }

@media all and (max-width: 767px) {
  .navdrop-content {
    background: #fff;
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.75);
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: opacity 0.15s linear;
    width: 100%; } }

.navdrop {
  position: relative;
  z-index: 5; }
  @media all and (max-width: 767px) {
    .navdrop:hover .navdrop-content, .navdrop:active .navdrop-content {
      opacity: 1;
      pointer-events: all; } }
  .navdrop:hover .navdrop-drop, .navdrop:active .navdrop-drop {
    border-radius: 1.09375rem 1.09375rem 0 0; }
  .navdrop:hover .navdrop-closed, .navdrop:active .navdrop-closed {
    display: none; }
  .navdrop:hover .navdrop-opened, .navdrop:active .navdrop-opened {
    display: block; }

/**
 * LIGHTBOX basic styles
 * by SalsaLab
 */
.slb-lightbox-container, .slb-lightbox-media {
  left: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw; }

.slb-lightbox-container {
  background: rgba(255, 255, 255, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  z-index: 10001; }
  .slb-lightbox-container.active {
    opacity: 1;
    pointer-events: all; }

.slb-lightbox-nav {
  height: 0; }
  .slb-lightbox-nav a {
    cursor: pointer;
    position: absolute;
    z-index: 10005; }

.slb-lightbox-prev {
  left: 0;
  top: 50%;
  transform: translateY(-50%); }

.slb-lightbox-next {
  right: 0;
  top: 50%;
  transform: translateY(-50%); }

.slb-lightbox-close {
  right: 0;
  top: 0; }

.slb-lightbox-media {
  z-index: 10002; }
  @media all and (min-width: 992px) {
    .slb-lightbox-media {
      padding: 0; } }
  .slb-lightbox-media figure,
  .slb-lightbox-media div {
    height: 100%;
    margin: 0;
    position: relative;
    width: 100%; }
  .slb-lightbox-media figure.loading:before {
    animation: loading 1s ease-in-out infinite alternate;
    background: #fff;
    content: '';
    border-radius: 2rem;
    display: block;
    height: 2rem;
    left: 50%;
    margin: -1rem 0 0 -1rem;
    position: absolute;
    top: 50%;
    width: 2rem;
    z-index: 10003; }
  .slb-lightbox-media img,
  .slb-lightbox-media video,
  .slb-lightbox-media iframe {
    animation: fadein 0.25s;
    height: auto;
    left: 50%;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.15s linear;
    z-index: 10002; }
    .slb-lightbox-media img.remove-queued,
    .slb-lightbox-media video.remove-queued,
    .slb-lightbox-media iframe.remove-queued {
      opacity: 0; }
  .slb-lightbox-media video {
    background: #000; }
  .slb-lightbox-media iframe {
    background: #000;
    border: 0;
    display: block;
    height: 100%;
    width: 100%; }
  .slb-lightbox-media figcaption:empty {
    display: none; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes loading {
  from {
    transform: scale(1); }
  to {
    transform: scale(3); } }

.slb-lightbox-active {
  overflow: hidden; }

/*
 * SCSS THEME BASIC TAGS File
 * by SalsaLab
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: "Open Sans", sans-serif; }

h2 {
  font-weight: 800; }

body {
  background: #fff;
  display: flex;
  flex-direction: column;
  font-size: 100%;
  font-weight: 300;
  justify-content: space-between;
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
  color: #2e3746;
  font-family: "Open Sans", sans-serif;
  font-size: 21px;
  letter-spacing: 0.23px;
  line-height: 24px;
  outline: none; }
  body .no-click {
    pointer-events: none;
    cursor: default;
    display: none; }
  body p,
  body h2,
  body h3,
  body h4 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: normal !important; }
    body p b,
    body h2 b,
    body h3 b,
    body h4 b {
      font-weight: 900 !important; }
  body h1 {
    font-weight: 900 !important;
    font-family: "Montserrat", sans-serif !important; }

html {
  font-size: 100%;
  line-height: 16px; }

.title-f30 h3 {
  color: #20c997;
  font-size: 1.875rem;
  font-family: "Open Sans", sans-serif;
  line-height: 31px;
  padding: 1rem;
  border: 1px solid #20c997;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
  letter-spacing: -0.83px;
  text-align: center;
  text-transform: uppercase; }
  @media all and (max-width: 575px) {
    .title-f30 h3 {
      font-size: 24px;
      line-height: 25px; } }

.wavy {
  border: 0;
  padding: 2.8rem 10rem;
  height: 30px;
  background: url(../images/bkg/ofera-line-subtitle.svg) no-repeat;
  background-position: center; }

.container-fluid {
  max-width: 1100px; }

.button {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.9px; }
  .button.purple-butt {
    -webkit-box-shadow: 0px 4px 16px -5px #6633cc;
    -moz-box-shadow: 0px 4px 16px -5px #6633cc;
    box-shadow: 0px 4px 16px -5px #6633cc;
    background: #259d00;
    border: solid 2px #259d00;
    border-radius: 30px;
    color: #fff;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.9px; }
    .button.purple-butt:active, .button.purple-butt:hover {
      background: transparent;
      border-color: #259d00;
      color: #259d00; }
      .button.purple-butt:active::after, .button.purple-butt:hover::after {
        color: #fff;
        display: block;
        padding-left: 0.5rem; }
  .button:active, .button:hover {
    background: transparent;
    color: #000;
    width: 182px; }
    .button:active::after, .button:hover::after {
      color: #fff;
      display: block;
      padding-left: 0.5rem; }

.button.color-alt {
  background: #087ef6;
  border: solid 2px #087ef6;
  color: #fff;
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 18px; }
  .button.color-alt:active, .button.color-alt:hover {
    background: transparent;
    border-color: #087ef6;
    color: #087ef6; }
    .button.color-alt:active::after, .button.color-alt:hover::after {
      color: #fff;
      display: block;
      padding-left: 0.5rem; }

.bottom-line {
  height: 8px;
  background: #259d00;
  width: 75px;
  margin-left: 0;
  border: 0; }

.ico-md.p0::before {
  padding: 0; }

.sec-osomos-top h4 {
  border-left: 12px solid #79cbed;
  padding: 0 1rem !important;
  font-size: 18px !important;
  margin-top: 0 !important; }

.sec-serv .to-left {
  display: flex;
  justify-content: space-between; }
  .sec-serv .to-left .the-text {
    background-color: #fff;
    padding: 2rem;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8rem;
    -webkit-box-shadow: 10px 10px 14px -5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 10px 10px 14px -5px rgba(0, 0, 0, 0.1);
    box-shadow: 10px 10px 14px -5px rgba(0, 0, 0, 0.1);
    min-height: 432px; }
    .sec-serv .to-left .the-text h2 {
      padding-bottom: 4rem; }
    .sec-serv .to-left .the-text img {
      margin-top: 14rem; }

.sec-serv.sec-servicio-left {
  margin: 4rem 0;
  min-height: 12rem;
  background: url(../images/bkg/int-serv-1.jpg) left/no-repeat;
  background-size: 50%; }

.sec-serv.sec-servicio-two {
  margin: 4rem 0;
  min-height: 12rem;
  background: url(../images/bkg/int-serv-2.jpg) right/no-repeat;
  background-size: 50%; }

.sec-serv.sec-servicio-three {
  margin: 4rem 0;
  min-height: 12rem;
  background: url(../images/bkg/int-serv-3.jpg) left/no-repeat;
  background-size: 50%; }

.sec-serv.sec-servicio-four {
  margin: 4rem 0;
  min-height: 12rem;
  background: url(../images/bkg/int-serv-4.jpg) right/no-repeat;
  background-size: 50%; }

.button {
  line-height: 24px;
  color: #fff;
  font-size: 1rem;
  align-items: center;
  display: inline-flex;
  font-weight: 600;
  justify-content: center;
  min-height: 1.6875rem;
  min-width: 7.668125rem;
  padding: 10px 30px;
  text-shadow: 0 1px 0 0 #fff;
  text-transform: uppercase; }

.bg-dark {
  background: #333; }

.bg-primary {
  background: #0275d8; }

.txt-center {
  text-align: left; }

@-webkit-keyframes rightleft {
  0% {
    background-position: 100% 0%; }
  50% {
    background-position: 50% 100%; }
  100% {
    background-position: 0% 50%; } }

@-moz-keyframes rightleft {
  0% {
    background-position: 100% 0%; }
  50% {
    background-position: 50% 100%; }
  100% {
    background-position: 0% 50%; } }

@keyframes rightleft {
  0% {
    background-position: 100% 0%; }
  50% {
    background-position: 50% 100%; }
  100% {
    background-position: 0% 50%; } }

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@keyframes Gradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@-webkit-keyframes gradient-bt {
  0% {
    background-position: 0% 0%; }
  50% {
    background-position: 0% 50%; }
  100% {
    background-position: 0% 100%; } }

@-moz-keyframes gradient-bt {
  0% {
    background-position: 0% 0%; }
  50% {
    background-position: 0% 50%; }
  100% {
    background-position: 0% 100%; } }

@keyframes gradient-bt {
  0% {
    background-position: 0% 0%; }
  50% {
    background-position: 0% 50%; }
  100% {
    background-position: 0% 100%; } }

.full-width .container-fluid {
  max-width: 100%;
  padding: 0; }
  .full-width .container-fluid .row {
    width: 100%; }

.intro-default .content-media p {
  align-items: center;
  background: #001769;
  border-radius: 50%;
  display: flex;
  height: 16.875rem;
  text-align: center;
  width: 16.875rem; }

.intro-default .content-media img {
  margin: auto; }

.intro-default h2 {
  line-height: 25px;
  color: #001769;
  font-size: 25px;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
  letter-spacing: -0.69px;
  text-align: left;
  width: 27.5625rem; }
  @media all and (max-width: 991px) {
    .intro-default h2 {
      text-align: center; } }

@media all and (max-width: 991px) {
  .intro-default .content-text {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center; } }

.intro-default .content-text p {
  color: #2f2f2f;
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.0625rem;
  letter-spacing: -0.5px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.5px;
  margin-left: 0;
  text-align: left; }
  @media all and (min-width: 577px) {
    .intro-default .content-text p {
      width: 65%; } }
  @media all and (max-width: 991px) {
    .intro-default .content-text p {
      margin: auto; } }
  .intro-default .content-text p:first-of-type {
    background-image: url(../images/bkg/linea2-blue.svg) no-repeat left bottom;
    padding-bottom: 2.2rem; }
  .intro-default .content-text p:last-of-type {
    margin: 0; }

.item-squared ul {
  margin: auto; }

.item-squared .subtitle {
  color: #20c997;
  font-size: 1.875rem;
  font-family: "Open Sans", sans-serif;
  line-height: 31px;
  padding: 1rem;
  border: 1px solid #20c997;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
  letter-spacing: -0.83px;
  margin: 5rem auto 4rem;
  text-align: center;
  text-transform: uppercase;
  width: 50%; }
  @media all and (max-width: 575px) {
    .item-squared .subtitle {
      font-size: 24px;
      line-height: 25px; } }

.item-squared li {
  margin-bottom: 0.3rem; }
  .item-squared li::before {
    background: #00e6e0;
    content: " ";
    display: block;
    height: 10px;
    left: -1.4rem;
    margin-right: 1.3125rem;
    position: absolute;
    top: 0.4rem;
    width: 10px; }

.item-squared h4 {
  line-height: 1.25rem;
  color: #2f2f2f;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: -0.56px;
  line-height: 24px;
  position: relative;
  text-align: left; }
  .item-squared h4 strong {
    margin-right: 0.5rem; }

.item-check ul {
  padding-top: 1.5rem; }

.item-check .subtitle-line {
  margin-bottom: 4.1875rem; }

.item-check img {
  object-fit: cover;
  width: 100%; }

.item-check p {
  margin: 0; }

.item-check li {
  padding-left: 2rem; }
  .item-check li::before {
    background: url(../images/bkg/icon-check.svg) no-repeat center;
    content: " ";
    display: block;
    height: 2.375rem;
    left: -2rem;
    margin-right: 1.3125rem;
    position: absolute;
    top: 0;
    width: 2.375rem; }

.item-check h4 {
  line-height: 1.25rem;
  color: #2f2f2f;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: -0.56px;
  line-height: 24px;
  margin-bottom: 3rem;
  position: relative;
  text-align: left; }
  .item-check h4 strong {
    margin-right: 0.5rem; }

.sec-procesos-top .container-fluid,
.sec-equipos-top .container-fluid,
.sec-como-top .container-fluid,
.sec-contact-top .container-fluid {
  height: 506px !important;
  min-height: 506px !important;
  margin-bottom: 6rem; }
  .sec-procesos-top .container-fluid .inner-pages,
  .sec-equipos-top .container-fluid .inner-pages,
  .sec-como-top .container-fluid .inner-pages,
  .sec-contact-top .container-fluid .inner-pages {
    position: relative;
    height: 506px !important;
    margin-bottom: 6rem; }
    .sec-procesos-top .container-fluid .inner-pages h2,
    .sec-equipos-top .container-fluid .inner-pages h2,
    .sec-como-top .container-fluid .inner-pages h2,
    .sec-contact-top .container-fluid .inner-pages h2 {
      position: absolute;
      font-size: 41px !important;
      background: #000;
      padding: 2rem;
      left: 0;
      width: 60%;
      bottom: -4rem; }

.yellow-title {
  display: flex;
  margin-top: -3rem;
  width: max-content;
  margin-left: 6rem;
  margin-right: auto;
  box-shadow: 3px -1px 15px 0px rgba(119, 119, 119, 0.49);
  -webkit-box-shadow: 3px -1px 15px 0px rgba(119, 119, 119, 0.49);
  -moz-box-shadow: 3px -1px 15px 0px rgba(119, 119, 119, 0.49); }
  .yellow-title.alt-tit {
    margin-right: 6rem;
    margin-left: auto; }
  .yellow-title h2 {
    background: #fbbc0f;
    font-size: 30px;
    width: auto;
    padding: 0.53rem 1rem;
    min-height: 89px;
    margin-bottom: 0;
    z-index: 999;
    flex: 3; }
  .yellow-title img {
    height: 89px; }

.sec-equipos-top .container-fluid .inner-pages h2 {
  font-size: 32px !important; }

.aviso-de-priv h2 {
  margin: 11rem 0 4rem;
  font-weight: 800;
  font-size: 30px;
  text-align: center; }

.sec-aviso .header .mnu a {
  color: #000; }

.sec-aviso .site-logo-link {
  background: url(../images/yellow-logo-black.svg) center/contain no-repeat; }

.sec-equipos h2.quienes-tit {
  font-weight: normal !important; }

.sec-equipos p b {
  font-weight: 800; }

.quienes-tit {
  font-size: 30px;
  font-weight: 800; }

.process-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 4rem; }
  .process-grid.grid-somos p {
    margin-top: 0 !important; }
  .process-grid div {
    display: flex;
    column-gap: 1rem; }
    .process-grid div p {
      flex: 1;
      color: #000 !important; }
      .process-grid div p span {
        font-weight: 800; }
    .process-grid div img {
      width: 201px;
      height: 168px; }

.blog-header .search-wrapper,
#sidebar .search-wrapper {
  opacity: 1;
  visibility: visible;
  max-height: initial; }

.search-wrapper {
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-out, opacity 0.8s;
  overflow: hidden; }
  .search-wrapper input:not([type="radio"]):not([type="checkbox"]) {
    border-bottom: 0;
    padding-left: 4rem; }
    .search-wrapper input:not([type="radio"]):not([type="checkbox"])::placeholder {
      opacity: 1;
      /* Firefox */ }

.show-search {
  opacity: 1;
  visibility: visible;
  max-height: 500px;
  transition: max-height 0.8s ease-in, opacity 0.8s; }

.pro-list {
  display: flex;
  text-align: left; }
  .pro-list ul li {
    margin-bottom: 3rem;
    background-image: url(../images/bkg/muve-bullet.svg);
    list-style: none;
    background-repeat: no-repeat;
    padding-left: 46px;
    background-position: center left;
    font-size: 20px;
    font-family: "Montserrat", sans-serif; }
    .pro-list ul li::before {
      content: none; }

.sec-contacto .sec-title::after {
  background-color: transparent !important; }

@media all and (max-width: 575px) {
  .quienes-text {
    display: block !important;
    overflow: hidden; }
    .quienes-text .quien-desc .subtitle-line {
      margin-left: 0 !important; }
  .sec-clientes .row {
    display: block;
    padding-left: 2rem; }
    .sec-clientes .row .col-lg-3 {
      max-width: 64%;
      margin-bottom: 4rem;
      margin-left: auto;
      margin-right: auto !important; }
  .sec-promosiones .col-lg-4 h2.price,
  .sec-promosiones .col-lg-4 h3.price {
    display: block; }
  .sec-ventajas .row {
    display: block; }
    .sec-ventajas .row .col-lg-3 {
      max-width: 100%; } }

.blog-content-item input:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus) + label,
.blog-content-item select.invalid:not(:focus) + label,
.blog-content-item select:invalid:not(:focus) + label,
.blog-content-item textarea:placeholder-shown:not(:focus) + label {
  color: #259d00;
  text-transform: uppercase;
  font-weight: 100; }

.blog-content-item input:not([type="radio"]):not([type="checkbox"]) + label,
.blog-content-item select + label,
.blog-content-item textarea + label {
  color: #259d00;
  text-transform: uppercase;
  font-weight: 100; }

.blog-content-item input:-internal-autofill-selected {
  appearance: menulist-button;
  background-color: transparent !important; }

.blog-content-item input:not([type="radio"]):not([type="checkbox"]),
.blog-content-item select,
.blog-content-item textarea {
  color: #e0e0e0;
  border-bottom: 1px solid #e0e0e0; }

.blog-content-item .search-wrapper form {
  width: 100%; }

.blog-content-item h3 {
  font-size: 18px;
  font-weight: 700;
  color: #333; }

.blog-content-item form {
  width: 70%; }
  .blog-content-item form > div:first-of-type {
    margin-right: 2rem; }
  .blog-content-item form > div:first-of-type, .blog-content-item form > div:nth-child(2) {
    display: inline-block;
    width: 46.8%; }
  .blog-content-item form .form-content {
    margin-top: 2rem; }
  .blog-content-item form .buttons {
    display: flex;
    justify-content: flex-end; }

@media all and (max-width: 769px) {
  .sec-page-top h2 {
    margin-bottom: 2rem; }
  .sec-home .sec-conexiones,
  .sec-home .sec-internet,
  .sec-contacto {
    background-position: bottom !important;
    padding-bottom: 310px; }
  .quienes-text .quien-desc {
    padding: 0 !important;
    width: 100% !important; }
  .mivi-reg {
    display: block !important; }
  .mivi-reg .mivi-text {
    width: 100% !important;
    padding: 0 !important; }
  .sec-home .sec-promosiones .grid-valores {
    display: block !important; }
  .sec-internet,
  .sec-promosiones,
  .wrapper-footer {
    padding-left: 2.5625rem !important;
    padding-right: 2.5625rem !important; }
  .contact-form {
    width: 80% !important; }
  .footer .footer-sm {
    display: block !important;
    text-align: left !important; }
  .sec-somos .sec-page-top h2 {
    margin-bottom: 0 !important;
    color: #000 !important; }
  .sec-serv .to-left {
    display: block !important; }
    .sec-serv .to-left .sec-title {
      font-size: 26px !important; }
  .sec-serv .to-left .the-text {
    width: 96% !important;
    display: block !important; }
  .sec-serv .to-left .the-text img {
    margin-top: 0rem; }
  .sec-serv.sec-servicio-left,
  .sec-serv.sec-servicio-two,
  .sec-serv.sec-servicio-three,
  .sec-serv.sec-servicio-four {
    background: none !important; }
  .sec-somos #ventajas {
    height: 162px !important; } }

.mt-4 {
  margin-top: 4rem; }

.foot-title {
  font-size: 20px;
  font-weight: 600;
  color: #fff; }

a.button {
  font-size: 14px;
  width: 180px;
  height: 44px;
  text-transform: uppercase;
  background: #fbbc0f;
  color: #fff;
  padding: 10px 18px;
  font-family: "Montserrat", sans-serif; }
  a.button:hover {
    border: 2px solid #fbbc0f; }

@media (min-width: 601px) and (max-width: 900px) {
  #home-slider .container-fluid .swiper-slide {
    background-size: cover !important; } }

@media (min-width: 320px) and (max-width: 600px) {
  .slide1 .swiper-slide {
    background-image: url("/user/themes/yellowcoating/images/banners/yellow-home-m.png") !important; }
  .slide2 .swiper-slide {
    background-image: url("/user/themes/yellowcoating/images/banners/yellow-home-2-m.png") !important; }
  .slide3 .swiper-slide {
    background-image: url("/user/themes/yellowcoating/images/banners/yellow-home-3-m.png") !important; }
  .slide4 .swiper-slide {
    background-image: url("/user/themes/yellowcoating/images/banners/yellow-home-4-m.png") !important; }
  .slide5 .swiper-slide {
    background-image: url("/user/themes/yellowcoating/images/banners/yellow-home-5-m.png") !important; }
  .contact-form {
    width: 90% !important; }
  .sec-contact-top .container-fluid {
    min-height: 506px !important;
    background-position: left !important; }
  .process-grid div img {
    width: 100%;
    height: auto; }
  .contact-background {
    display: none; }
  .right-header {
    width: auto !important;
    margin-top: 0 !important;
    display: contents; }
  .sec-page-top h2 {
    font-size: 30px !important;
    margin-top: 0 !important;
    text-shadow: 1px 1px 4px #969696; }
  .site-logo-link {
    background: url(../images/yellow-logo-black.svg) center/contain no-repeat; }
  .header-bntns {
    flex-direction: column;
    justify-content: center;
    width: 100%; }
    .header-bntns .button {
      margin-left: 0 !important; }
  .quienes-info {
    display: none !important; }
  .sec-home .grid-procesos {
    display: block !important; }
  .sec-home .grid-procesos div h1 {
    font-size: 25px !important; }
  .footer .footer-grid {
    display: block !important; }
  .foot-firt-trg .foot-line-twoe {
    display: block !important; }
    .foot-firt-trg .foot-line-twoe p {
      margin-bottom: 1rem !important; }
  .sec-como-top .container-fluid .inner-pages h2,
  .sec-equipos-top .container-fluid .inner-pages h2,
  .sec-procesos-top .container-fluid .inner-pages h2,
  .sec-contact-top .container-fluid .inner-pages h2 {
    position: inherit !important;
    width: 100% !important;
    padding: 0 !important;
    background: transparent; }
  .yellow-title {
    flex-direction: column;
    margin-top: 0 !important;
    margin-left: 0 !important;
    background: #fbbc0f; }
  .yellow-title {
    width: 100% !important; }
    .yellow-title h2 {
      font-size: 26px !important; }
  .process-grid {
    display: block !important; }
    .process-grid div {
      display: block !important; }
  .content-form {
    padding: 0 !important;
    display: block !important;
    width: 90% !important;
    margin: auto !important; }
  .sec-cont .sec-contact-top {
    min-height: auto !important; }
  .header .container-fluid {
    border-bottom: 0 !important; }
  .subheader .content {
    justify-content: center !important; }
  .sec-page-top h3 {
    color: #000 !important; }
  .header .mnu {
    border-right: 0 !important;
    margin-right: 0 !important; }
  .footer-sm:last-of-type {
    margin-bottom: 2rem; }
  .header .footer-sm .ico {
    margin-right: 1rem; }
  .soluciones-reg .solu-title {
    width: 223px !important; }
  .soluciones-reg .sec-title {
    font-size: 30px !important; }
  .soluciones-reg .soluciones-icons {
    display: block !important; }
  .sec-promosiones .sec-title {
    font-size: 20px !important; }
  #contact-form.row {
    margin-left: 1rem !important;
    margin-right: 0 !important;
    margin-top: 3rem; }
  footer .foot-line-one {
    flex-direction: column; }
  .foot-firt-trg {
    flex-direction: column; }
  .sec-como-top .container-fluid .content h2, .sec-page-top h2 {
    color: #fff !important;
    background: transparent; }
  .sec-como-top .container-fluid .content p {
    color: #000 !important; }
  .sec-como-funciona .sec-clientes {
    padding: 2rem !important; }
  .content-alt {
    width: auto !important; }
  .sec-internet,
  .sec-promosiones,
  .wrapper-footer {
    padding-left: 1rem !important;
    padding-right: 1rem !important; }
  .sec-como-funciona .sec-internet.alt .pro-list .content-alt {
    display: block !important; }
  .sec-como-funciona .sec-internet.alt .pro-list {
    background: transparent !important; }
  .fundadoras .co-fun-foto img {
    width: 40%; }
  .fundadoras .content-alt.mivision div h4 {
    font-size: 20px !important; }
  section .sec-title {
    font-size: 30px !important; }
  .swiper-slide .container-fluid {
    width: 90% !important;
    margin: 0 auto; }
    .swiper-slide .container-fluid h2 {
      font-size: 32px !important;
      width: auto !important; }
  #home-slider .container-fluid .swiper-slide {
    background-size: cover !important;
    background-position: center !important; }
  #home-slider .container-fluid .swiper-slide .container-fluid {
    justify-content: center !important; }
  .whats #dk-whatsapp {
    display: none; }
  .whats #mb-whatsapp {
    display: block !important; }
    .whats #mb-whatsapp img {
      width: 40px !important; }
  input:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus) + label,
  select.invalid:not(:focus) + label,
  select:invalid:not(:focus) + label,
  textarea:placeholder-shown:not(:focus) + label {
    font-size: 16px !important; }
  .sec-como-top .container-fluid,
  .sec-equipos-top .container-fluid,
  .sec-procesos-top .container-fluid {
    background-size: cover !important; }
  .header {
    position: initial !important; }
  .sec-home .sec-conexiones {
    padding: 2rem 0 !important; }
    .sec-home .sec-conexiones img {
      margin-bottom: 1rem; }
  .sec-promosiones {
    padding: 0rem 0 1rem !important; }
  .sec-conexiones img {
    margin: 1rem 0; }
  .sec-como-top .container-fluid,
  .sec-equipos-top .container-fluid,
  .sec-procesos-top .container-fluid {
    margin-bottom: 0rem !important; }
  .accordion-button {
    margin-left: auto;
    display: block; }
  #ventajas img {
    margin-bottom: 2rem; }
  .sec-como-funciona .sec-clientes p {
    margin-top: 1rem !important; }
  .sec-internet p span {
    font-size: 25px !important;
    margin-top: 1rem; }
  .contact-top p {
    margin: 2rem 0 !important; }
  .sec-contacto, .sec-home .sec-conexiones {
    padding-bottom: 0 !important; }
  .sec-home .sec-internet {
    padding: 1rem 0 !important; }
  #ventajas .accordion-button {
    order: 3; }
    #ventajas .accordion-button img {
      margin-bottom: 0; }
  #accordionExample img {
    margin-bottom: 0; }
  .footer .footer-grid div a {
    margin-bottom: 2rem !important;
    display: block !important; }
  .footer .footer-grid div p {
    min-height: auto !important; } }

.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

.animated {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-duration: 1s;
  animation-fill-mode: both; }

@-webkit-keyframes bounce-in-up {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounce-in-up {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounce-in-up;
  animation-name: bounce-in-up; }

@-webkit-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in; }

@-webkit-keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

@keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

.shake {
  -webkit-animation-name: shake;
  animation-name: shake; }

@-webkit-keyframes flip-iny {
  from {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg); }
  40% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg); }
  60% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg); }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes flip-iny {
  from {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg); }
  40% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg); }
  60% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg); }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

.flip-iny {
  -webkit-animation-name: flip-iny;
  animation-name: flip-iny;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse; }

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0); }
  100% {
    transform: translate3d(-1692px, 0, 0); } }

@keyframes fadein {
  0% {
    opacity: 0; }
  66% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadein {
  0% {
    opacity: 0; }
  66% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes flash {
  from,
  50%,
  to {
    opacity: 1; }
  25%,
  75% {
    opacity: 0; } }

@keyframes flash {
  from,
  50%,
  to {
    opacity: 1; }
  25%,
  75% {
    opacity: 0; } }

.flash {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-name: flash;
  animation-name: flash; }

@-webkit-keyframes slide-in-down {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slide-in-down {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes slide-in-up {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slide-in-up {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fancy-sub {
  font-size: 23px;
  color: #259d00; }

.sec-page-top {
  overflow: hidden; }
  .sec-page-top p {
    line-height: 1.25rem;
    color: #2f2f2f;
    font-size: 1.25rem;
    font-weight: normal;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation: 2s ease 0s normal forwards 1 fadein;
    animation: 2s ease 0s normal forwards 1 fadein;
    opacity: 1;
    letter-spacing: -0.58px;
    text-align: left; }
    .sec-page-top p strong {
      font-weight: 700; }
  .sec-page-top .slogan {
    color: #259d00;
    font-size: 20px;
    margin-top: 0;
    font-weight: 300; }
  .sec-page-top h2 {
    font-size: 39px;
    color: #fff;
    letter-spacing: -1.39px;
    text-align: left;
    font-weight: normal;
    margin-bottom: 1rem;
    font-family: "Montserrat", sans-serif; }
    .sec-page-top h2 b {
      color: #fbbc0f; }
    @media all and (max-width: 575px) {
      .sec-page-top h2 {
        color: #000;
        font-size: 2.5rem;
        font-family: "Open Sans", sans-serif;
        line-height: 2.5rem;
        margin-top: 3rem !important; } }
  .sec-page-top h3 {
    color: #fff;
    font-size: 36px;
    font-weight: 100;
    margin: 1rem 0; }
  @media all and (max-width: 575px) {
    .sec-page-top .button {
      margin-bottom: 3rem; } }
  .sec-page-top h4 {
    font-size: 1.25rem;
    color: #259d00;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation: 1.5s ease 0s normal forwards 1 fadein;
    animation: 1.5s ease 0s normal forwards 1 fadein;
    opacity: 1;
    font-weight: 600;
    letter-spacing: -0.83px;
    margin-top: 1rem;
    text-align: left;
    text-transform: uppercase; }
    @media all and (max-width: 575px) {
      .sec-page-top h4 {
        color: #000;
        margin-bottom: 3rem; } }

.sec-empresas-top h2::after,
.sec-aduanas-top h2::after {
  background: #00e6e0;
  bottom: 12px;
  content: " ";
  display: block;
  height: 6px;
  left: 0;
  position: absolute;
  right: 0;
  width: 115px; }

.sec-aduanas-top h4 {
  max-width: 30%;
  font-weight: 600;
  font-size: 20px; }
  @media all and (max-width: 575px) {
    .sec-aduanas-top h4 {
      max-width: 100%; } }

.sec-map-top h2 {
  padding: 0.5rem 1rem 1rem;
  position: relative;
  font-size: 45px;
  display: inline-flex;
  font-weight: 600;
  letter-spacing: -1.39px;
  margin: 3rem auto;
  text-transform: uppercase; }

.sec-servicios .subtitle-line {
  font-weight: 600;
  font-size: 45px; }

.sec-ayuda-top h2,
.sec-servicios h2 {
  padding: 0.5rem 1rem 1rem;
  position: relative;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.6rem;
  letter-spacing: 1px;
  margin: 0 auto 3rem;
  text-transform: uppercase; }

.sec-servicios h2 {
  margin-top: 3rem;
  padding-top: 0; }

.sec-promosiones {
  padding: 3rem 0 8rem;
  position: relative; }
  .sec-promosiones .sec-title {
    color: #2e3746;
    font-weight: normal;
    font-size: 36px;
    display: inline-flex;
    font-family: "Montserrat", sans-serif; }
  .sec-promosiones .row {
    align-items: center; }
  .sec-promosiones .col-lg-8 {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6rem; }
  .sec-promosiones .subtitle-line {
    font-size: 40px !important; }
  .sec-promosiones .col-lg-4 {
    background-color: #259d00;
    padding: 0 1.5rem;
    color: #fff;
    border-radius: 30px;
    height: 330px; }
    .sec-promosiones .col-lg-4 h2,
    .sec-promosiones .col-lg-4 h3 {
      color: #fff;
      padding-top: 1rem;
      padding-bottom: 0; }
      .sec-promosiones .col-lg-4 h2.price,
      .sec-promosiones .col-lg-4 h3.price {
        margin-bottom: 1rem;
        padding-top: 0; }
    .sec-promosiones .col-lg-4 p {
      margin-top: 0; }
    .sec-promosiones .col-lg-4:nth-child(2) {
      background-color: #fff;
      color: #259d00;
      min-height: 400px;
      -webkit-box-shadow: 0px 1px 30px -8px rgba(0, 0, 0, 0.4);
      -moz-box-shadow: 0px 1px 30px -8px rgba(0, 0, 0, 0.4);
      box-shadow: 0px 1px 30px -8px rgba(0, 0, 0, 0.4); }
      .sec-promosiones .col-lg-4:nth-child(2) h2,
      .sec-promosiones .col-lg-4:nth-child(2) h3 {
        color: #259d00; }
    .sec-promosiones .col-lg-4 ul {
      text-align: left;
      padding-left: 0; }
      .sec-promosiones .col-lg-4 ul li {
        list-style: none;
        background-image: url(../images/bkg/ofera-check.png);
        background-repeat: no-repeat;
        line-height: 30px;
        padding-left: 46px;
        background-position: center left;
        margin-bottom: 1rem; }
        .sec-promosiones .col-lg-4 ul li::before {
          content: ""; }
    .sec-promosiones .col-lg-4 .button.alt {
      border: 3px solid #fff; }

.sec-como-funciona .sec-internet.alt {
  position: relative;
  padding-bottom: 4rem; }
  .sec-como-funciona .sec-internet.alt .container-fluid {
    max-width: 100%; }

.sec-clientes .container-fluid {
  padding-left: 0;
  padding-right: 0; }

.sec-clientes .row {
  align-items: center; }

.sec-clientes .col-lg {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6rem;
  color: #fff;
  padding: 2rem 0 5rem;
  background: #259d00 url(../images/bkg/ofera-dot-back-dos.png) left repeat; }
  .sec-clientes .col-lg h2 {
    color: #fff; }
  .sec-clientes .col-lg p {
    margin-top: 0; }

.sec-clientes .row {
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  margin-top: -10rem; }

.sec-clientes .col-lg-3 {
  background: #fff url(../images/bkg/client-dots.png) 230px 10px no-repeat;
  text-align: left;
  margin-right: 3rem;
  margin-top: 1rem;
  border-radius: 10px;
  padding: 0 1.6rem 0;
  flex: 0 0 30%;
  max-width: 30%;
  -webkit-box-shadow: 0px 1px 30px -8px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 1px 30px -8px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 1px 30px -8px rgba(0, 0, 0, 0.4); }
  .sec-clientes .col-lg-3:last-child {
    margin-right: 0; }
  .sec-clientes .col-lg-3 .header-cli {
    display: flex;
    margin-left: -4rem;
    height: 68px; }
    .sec-clientes .col-lg-3 .header-cli img {
      width: 87px;
      height: 87px;
      position: absolute;
      top: -10px; }
    .sec-clientes .col-lg-3 .header-cli div p {
      margin-bottom: 0;
      padding-left: 6rem; }
      .sec-clientes .col-lg-3 .header-cli div p:last-of-type {
        margin-top: 0; }
      .sec-clientes .col-lg-3 .header-cli div p:first-of-type {
        margin-top: 1.5rem;
        color: #259d00;
        font-size: 1.25rem; }

.sec-ventajas {
  padding: 3rem 0 0; }
  .sec-ventajas .col-lg-6 {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem; }
  .sec-ventajas .col-lg-3 {
    margin-right: 1rem;
    flex: 0 0 25%;
    max-width: 23%; }
    .sec-ventajas .col-lg-3:nth-last-of-type {
      margin-right: 0; }
    .sec-ventajas .col-lg-3 h4 {
      font-size: 1.25rem;
      font-weight: 100; }

.subtitle-line {
  padding: 0.5rem 1rem 1rem;
  position: relative;
  color: #259d00;
  font-size: 48px;
  display: inline-flex;
  letter-spacing: -1.39px;
  margin: auto;
  padding: 1.25rem 0 0.8rem; }

.bg-primary {
  background: #f81676; }

.bg-textura p:first-of-type {
  width: 70%;
  margin-bottom: 3rem; }

.sec-banner-fullwidth h2 {
  padding: 0.5rem 1rem 1rem;
  position: relative;
  font-size: 45px;
  display: inline-flex;
  font-weight: 600;
  letter-spacing: -1.39px;
  margin: auto;
  text-transform: uppercase;
  padding: 1.25rem 0 0.8rem; }

.sec-banner-fullwidth.bg-primary {
  background: #259d00 url(../images/bkg/fedi-transp.jpg) no-repeat center bottom;
  background-size: cover;
  padding: 2.7rem 0;
  margin: 3rem 0; }
  .sec-banner-fullwidth.bg-primary p {
    line-height: 1.25rem;
    color: #fff;
    font-size: 1.25rem;
    font-weight: 700;
    letter-spacing: -0.94px; }
  .sec-banner-fullwidth.bg-primary h2 {
    color: #fff;
    font-size: 2.5rem;
    font-family: "Open Sans", sans-serif;
    line-height: 2.5rem;
    text-transform: initial; }

.sec-banner-fullwidth.bg-primary-ad {
  background: #259d00 url(../images/bkg/fedi-adu-blue.jpg) no-repeat center bottom;
  background-size: cover;
  padding: 2.7rem 0 3.7rem 0;
  margin: 3rem 0; }
  .sec-banner-fullwidth.bg-primary-ad p {
    line-height: 1.25rem;
    color: #fff;
    font-size: 1.25rem;
    font-weight: 700;
    letter-spacing: -0.94px; }
  .sec-banner-fullwidth.bg-primary-ad h2 {
    color: #fff; }

.bg-textura {
  padding: 5rem 0; }

.bg-blue-dark {
  background-color: #0275d8; }

.intro-default {
  background: #efefef; }

.sec-reto-top .quienes-text,
.empresas-servicios-info .quienes-text {
  margin: 2.5rem 0; }

.sec-reto-top .row,
.empresas-servicios-info .row {
  justify-content: space-around; }
  .sec-reto-top .row .on-item,
  .empresas-servicios-info .row .on-item {
    max-width: 50%;
    padding: 0; }
    @media all and (max-width: 575px) {
      .sec-reto-top .row .on-item,
      .empresas-servicios-info .row .on-item {
        max-width: 100%; } }
    .sec-reto-top .row .on-item:first-of-type,
    .empresas-servicios-info .row .on-item:first-of-type {
      padding-right: 38px; }
    .sec-reto-top .row .on-item:last-of-type,
    .empresas-servicios-info .row .on-item:last-of-type {
      padding-left: 38px; }
  .sec-reto-top .row .serv-desc,
  .empresas-servicios-info .row .serv-desc {
    text-align: left; }
    .sec-reto-top .row .serv-desc .subtitle-line,
    .empresas-servicios-info .row .serv-desc .subtitle-line {
      margin-bottom: 0;
      padding-bottom: 0;
      line-height: 0.9;
      text-transform: initial;
      font-size: 2.5rem; }

.empresas-servicios-info {
  padding: 5rem 0; }
  .empresas-servicios-info .on-item:last-of-type img {
    padding-top: 28px; }
  .empresas-servicios-info .on-item:last-of-type .serv-desc p {
    margin-top: 3.8rem; }
  .empresas-servicios-info .on-item:first-of-type .serv-desc p {
    margin-top: 2rem; }

.sec-metodologia .container-fluid {
  max-width: 100%; }
  .sec-metodologia .container-fluid .col-lg-6 {
    text-align: left;
    padding: 2rem 2rem 2rem 8rem; }
    @media all and (max-width: 575px) {
      .sec-metodologia .container-fluid .col-lg-6 {
        max-width: 100%;
        width: 100%;
        padding: 2rem; } }
    .sec-metodologia .container-fluid .col-lg-6 .serv-list {
      text-align: left;
      margin: initial;
      padding-left: 1em; }
      .sec-metodologia .container-fluid .col-lg-6 .serv-list li {
        margin-bottom: 14px;
        list-style: none;
        font-size: 21px; }
        .sec-metodologia .container-fluid .col-lg-6 .serv-list li::before {
          content: "\2022";
          color: #00e6e0;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: 1em; }
    .sec-metodologia .container-fluid .col-lg-6 h3 {
      color: #79cbed;
      margin-bottom: 1rem; }
  .sec-metodologia .container-fluid .back-img {
    background: url(../images/bkg/fedi-servi-image.jpg) no-repeat center bottom;
    background-size: cover; }

.sec-como-funciona .sec-clientes .grid-valores {
  display: flex;
  column-gap: 0.8rem;
  margin-top: 4rem;
  align-items: center; }
  .sec-como-funciona .sec-clientes .grid-valores div {
    flex: 1; }

.sec-como-funciona .sec-clientes .big-grid {
  margin-top: 4rem; }
  .sec-como-funciona .sec-clientes .big-grid .grid-valores {
    margin-top: 0; }

.fundadoras .sec-title {
  color: #2e3746;
  font-weight: normal;
  display: inline-flex;
  font-family: "Montserrat", sans-serif; }

.fundadoras .container-fluid {
  max-width: 100%; }

.fundadoras .content-alt.mivision {
  justify-content: center; }
  .fundadoras .content-alt.mivision div {
    max-width: 440px; }
    .fundadoras .content-alt.mivision div h4 {
      font-size: 30px;
      font-weight: normal;
      color: #259d00;
      margin: 1rem 0 2.2rem;
      font-family: "Montserrat", sans-serif; }
    .fundadoras .content-alt.mivision div p {
      font-size: 18px; }

.fundadoras .co-fun-foto {
  background: #2e3746;
  margin-top: 4rem; }
  .fundadoras .co-fun-foto img {
    margin-top: 4rem; }

.sec-internet {
  margin-top: 3rem; }
  .sec-internet p {
    color: #fff;
    font-size: 20px; }
  .sec-internet .int-logos {
    margin: 4rem 0 8rem; }
  .sec-internet .col-lg-8 {
    margin: 4rem auto; }
  .sec-internet .col-lg-2 img {
    width: 77px;
    height: 77px; }
  .sec-internet .col-lg-3 {
    margin-right: 1rem;
    flex: 0 0 25%;
    max-width: 23%; }
    .sec-internet .col-lg-3:nth-last-of-type {
      margin-right: 0; }
    .sec-internet .col-lg-3 h4 {
      font-size: 1.25rem;
      font-weight: 100; }

.sec-somos #ventajas {
  background: url(../images/bkg/int-todo-back.png) no-repeat center;
  background-size: cover;
  height: 352px;
  position: relative;
  padding-bottom: 1px; }
  .sec-somos #ventajas .col-lg-6 {
    margin: 4rem auto 0;
    font-size: 20px; }

.sec-servicio .col-lg-6 {
  margin: 4rem auto; }
  .sec-servicio .col-lg-6 h2 {
    padding-bottom: 0; }
  .sec-servicio .col-lg-6 h4 {
    font-size: 25px;
    font-weight: 100;
    padding-top: 0; }

.sec-servicio .col-lg-3 {
  margin-right: 1rem;
  flex: 0 0 25%;
  max-width: 23%; }
  .sec-servicio .col-lg-3:nth-last-of-type {
    margin-right: 0; }
  .sec-servicio .col-lg-3 h4 {
    font-size: 1.25rem;
    font-weight: 100; }

.sec-blog main.content {
  background: #fff;
  padding: 0 0 4rem; }
  .sec-blog main.content .blog-header {
    padding: 4rem 26rem 8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #259d00;
    color: #fff;
    position: relative; }
    .sec-blog main.content .blog-header:before, .sec-blog main.content .blog-header:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 25px;
      height: 200px;
      background: radial-gradient(closest-side, #fff, #fff 63%, #259d00 65%);
      background-size: 146px 90px;
      background-position: 0px 169px;
      background-repeat: repeat-x; }
    .sec-blog main.content .blog-header:after {
      background: radial-gradient(closest-side, #259d00, #259d00 60%, #fff 63%);
      background-size: 146px 90px;
      background-position: 73px -64px;
      background-repeat: repeat-x;
      bottom: -174px; }
    .sec-blog main.content .blog-header .search-wrapper {
      width: 70%; }
      .sec-blog main.content .blog-header .search-wrapper .search-input {
        border: 1px solid #fff;
        border-radius: 100px;
        background: url(../images/bkg/sky-search.svg);
        background-repeat: no-repeat;
        background-position-x: 97%;
        padding: 0 2rem;
        background-position-y: 6px; }
    .sec-blog main.content .blog-header h2 {
      text-transform: uppercase;
      margin-bottom: 2rem; }
  .sec-blog main.content .posts-item-dest {
    display: flex;
    position: absolute;
    top: -5rem;
    max-height: 340px; }
    .sec-blog main.content .posts-item-dest .dest-text {
      flex: 1;
      padding: 2rem; }
      .sec-blog main.content .posts-item-dest .dest-text a {
        color: #000;
        margin-bottom: 2rem;
        display: block;
        font-size: 28px;
        font-weight: 700; }
      .sec-blog main.content .posts-item-dest .dest-text p {
        color: #fff; }
    .sec-blog main.content .posts-item-dest .dest-im {
      flex: 1; }
      .sec-blog main.content .posts-item-dest .dest-im img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .sec-blog main.content .content-wrapper {
    max-width: 1100px;
    margin: auto;
    display: flex;
    padding: 18rem 0 4rem;
    position: relative; }
    .sec-blog main.content .content-wrapper #listing {
      width: 70%;
      display: grid;
      grid-template-columns: 4fr 4fr;
      column-gap: 1rem; }
      .sec-blog main.content .content-wrapper #listing .list-item {
        border-bottom: 4px solid #259d00;
        margin-bottom: 2rem;
        padding-bottom: 1rem; }
        .sec-blog main.content .content-wrapper #listing .list-item .p-name {
          margin: 1rem 0 2rem; }
          .sec-blog main.content .content-wrapper #listing .list-item .p-name .list-blog-date {
            display: none; }
          .sec-blog main.content .content-wrapper #listing .list-item .p-name a {
            color: #000;
            font-size: 20px; }
        .sec-blog main.content .content-wrapper #listing .list-item .tags {
          margin-top: 0.5rem;
          display: block; }
    .sec-blog main.content .content-wrapper #sidebar {
      width: 30%;
      margin-left: 2rem; }
      .sec-blog main.content .content-wrapper #sidebar .search-wrapper {
        display: none; }
      .sec-blog main.content .content-wrapper #sidebar .b-categories {
        padding: 1rem;
        margin-bottom: 2rem; }
        .sec-blog main.content .content-wrapper #sidebar .b-categories h4 {
          border-bottom: 1px solid #259d00;
          padding-bottom: 1rem;
          margin-bottom: 1rem;
          font-size: 20px;
          color: #000; }
        .sec-blog main.content .content-wrapper #sidebar .b-categories .tags {
          display: block; }
          .sec-blog main.content .content-wrapper #sidebar .b-categories .tags a {
            display: block;
            color: #000; }
      .sec-blog main.content .content-wrapper #sidebar .recent-post {
        background: #259d00;
        padding: 1rem; }
        .sec-blog main.content .content-wrapper #sidebar .recent-post h4 {
          color: #fff;
          margin-bottom: 2rem;
          font-size: 20px; }
        .sec-blog main.content .content-wrapper #sidebar .recent-post ul {
          padding-left: 0; }
          .sec-blog main.content .content-wrapper #sidebar .recent-post ul li {
            list-style: none;
            border-top: 1px solid #fff;
            padding: 1rem 0;
            display: flex; }
            .sec-blog main.content .content-wrapper #sidebar .recent-post ul li::before {
              content: none; }
            .sec-blog main.content .content-wrapper #sidebar .recent-post ul li .imagencita {
              flex: 1; }
              .sec-blog main.content .content-wrapper #sidebar .recent-post ul li .imagencita img {
                width: 124px;
                height: 72px; }
            .sec-blog main.content .content-wrapper #sidebar .recent-post ul li a {
              color: #fff;
              font-weight: 100;
              flex: 1;
              margin-right: 1rem; }

.blog-content-item {
  background: #fff; }
  .blog-content-item #item {
    max-width: 1100px;
    margin: auto;
    display: flex;
    padding: 2rem 0;
    position: relative; }
    .blog-content-item #item .h-entry {
      flex: 1; }
      .blog-content-item #item .h-entry .list-blog-header {
        padding-top: 650px; }
        .blog-content-item #item .h-entry .list-blog-header img {
          position: absolute;
          width: 100%;
          top: 3rem;
          max-height: 600px;
          object-fit: cover; }
      .blog-content-item #item .h-entry a {
        color: #000;
        display: block;
        margin: 1rem 0;
        font-size: 20px; }
      .blog-content-item #item .h-entry hr {
        border: 2px solid #259d00;
        width: 10%;
        margin-left: 0;
        margin-top: 2rem;
        margin-bottom: 2rem; }
      .blog-content-item #item .h-entry h4 {
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .blog-content-item #item .h-entry h4 span {
          font-size: 12px;
          color: #2e3746; }
    .blog-content-item #item #sidebar {
      width: 30%;
      margin-left: 2rem;
      margin-top: 650px; }
      .blog-content-item #item #sidebar .search-wrapper {
        margin-bottom: 2rem; }
        .blog-content-item #item #sidebar .search-wrapper .search-input {
          border: 1px solid #000;
          border-radius: 100px;
          background: url(../images/bkg/sky-search.svg);
          background-repeat: no-repeat;
          background-position-x: 97%;
          padding: 0 2rem;
          background-position-y: 6px; }
      .blog-content-item #item #sidebar .b-categories {
        padding: 1rem;
        margin-bottom: 2rem;
        color: #000; }
        .blog-content-item #item #sidebar .b-categories h4 {
          border-bottom: 1px solid #259d00;
          padding-bottom: 1rem;
          margin-bottom: 1rem;
          font-size: 20px; }
        .blog-content-item #item #sidebar .b-categories .tags {
          display: block; }
          .blog-content-item #item #sidebar .b-categories .tags a {
            display: block;
            color: #000; }
      .blog-content-item #item #sidebar .recent-post {
        background: #259d00;
        padding: 1rem; }
        .blog-content-item #item #sidebar .recent-post h4 {
          color: #fff;
          margin-bottom: 2rem;
          font-size: 20px; }
        .blog-content-item #item #sidebar .recent-post ul {
          padding-left: 0; }
          .blog-content-item #item #sidebar .recent-post ul li {
            list-style: none;
            border-top: 1px solid #fff;
            padding: 1rem 0;
            display: flex; }
            .blog-content-item #item #sidebar .recent-post ul li::before {
              content: none; }
            .blog-content-item #item #sidebar .recent-post ul li .imagencita {
              flex: 1; }
              .blog-content-item #item #sidebar .recent-post ul li .imagencita img {
                width: 124px;
                height: 72px; }
            .blog-content-item #item #sidebar .recent-post ul li a {
              color: #fff;
              font-weight: 100;
              flex: 1;
              margin-right: 1rem; }

#ventajas .col-lg {
  background: #259d00; }

#ventajas .row {
  align-items: flex-start;
  max-width: 1280px;
  margin-top: 4rem; }

section .sec-title {
  font-size: 30px;
  color: #fff; }

section.sec-conexiones .sec-title {
  font-size: 30px;
  font-weight: normal;
  color: #000;
  font-family: "Montserrat", sans-serif; }

section.sec-conexiones p {
  color: #000;
  font-size: 20px;
  margin: 1.6rem 0; }

#testimonios {
  background: #f0f0f0;
  padding: 5rem 0 3rem; }
  #testimonios .carousel-inner {
    min-height: 280px; }
    #testimonios .carousel-inner .carousel-caption {
      position: initial;
      padding-top: 60px;
      padding-bottom: 60px; }
      #testimonios .carousel-inner .carousel-caption h5 {
        font-size: 17px;
        color: #2f2f2f;
        font-family: "Montserrat", sans-serif; }
      #testimonios .carousel-inner .carousel-caption hr {
        width: 80%;
        height: 2px;
        background-color: #259d00;
        border: 0; }
      #testimonios .carousel-inner .carousel-caption p {
        font-size: 16px;
        font-style: italic;
        color: #2e3746;
        font-family: "Montserrat", sans-serif; }
  #testimonios .carousel-indicators li {
    box-sizing: content-box;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 12px;
    height: 12px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #259d00;
    background-clip: padding-box;
    border-top: none;
    border-bottom: none;
    opacity: 0.5;
    transition: opacity 0.6s ease;
    border-radius: 10px !important; }
    #testimonios .carousel-indicators li.active {
      opacity: 1; }
  #testimonios ol > li::before {
    content: ""; }

.sec-home .sec-contacto {
  margin-top: 0; }

#home-slider .container-fluid {
  width: 100%;
  max-width: 100%;
  padding: 0; }
  #home-slider .container-fluid .swiper-slide {
    background-repeat: no-repeat;
    height: 665px;
    width: 100%;
    background-size: 100% 605px;
    margin: auto; }
    #home-slider .container-fluid .swiper-slide .container-fluid {
      width: 100%;
      max-width: 1100px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: 100%;
      justify-content: flex-end; }
      #home-slider .container-fluid .swiper-slide .container-fluid h2 {
        font-size: 39px;
        color: #fff;
        letter-spacing: -1.39px;
        text-align: left;
        font-weight: 400;
        margin-bottom: 1rem;
        width: 532px; }
        #home-slider .container-fluid .swiper-slide .container-fluid h2.caption-black {
          color: #000; }
        #home-slider .container-fluid .swiper-slide .container-fluid h2 b {
          color: #fbbc0f; }
      #home-slider .container-fluid .swiper-slide .container-fluid a.button:hover {
        color: #fff !important; }

#home-slider .swiper-pagination {
  bottom: 8rem;
  left: inherit;
  position: absolute;
  text-align: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 10;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

#home-slider .swiper-pagination-bullet {
  background: #fbbc0f;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  border: 0; }
  #home-slider .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #fff;
    border: 4px solid #fbbc0f;
    width: 15px;
    height: 15px; }

.header {
  background: transparent;
  height: auto;
  padding: 0;
  z-index: 888;
  position: absolute;
  width: 100%; }
  .header .footer-sm {
    margin-left: 1rem; }
    @media all and (max-width: 769px) {
      .header .footer-sm {
        margin-right: 4rem;
        text-align: center; } }
    @media all and (max-width: 991px) {
      .header .footer-sm .ico {
        height: 25px;
        width: 25px; }
        .header .footer-sm .ico::before {
          position: relative; } }
  @media all and (max-width: 991px) {
    .header .footer-sm-fb::before {
      font-size: 1.2rem;
      top: -0.2rem; } }
  @media all and (max-width: 991px) {
    .header .footer-sm-tw::before {
      font-size: 1.2rem;
      top: -0.1rem; } }
  @media all and (max-width: 991px) {
    .header .footer-sm-in::before {
      font-size: 1.4rem;
      top: -0.2rem; } }
  .header .container-fluid {
    min-height: 73px;
    z-index: 10;
    border-bottom: 2px solid #000; }
  @media all and (max-width: 769px) {
    .header #mnu-Search {
      margin: auto; } }
  .header .mnu {
    padding: 1rem 0;
    border-right: 2px solid #000;
    margin-right: 1.5rem; }
    .header .mnu:last-of-type {
      border-right: none;
      margin-right: 0; }
    .header .mnu a {
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      color: #fff;
      letter-spacing: 0.49px;
      padding-right: 1.5rem;
      font-family: "Montserrat", sans-serif; }
      @media all and (max-width: 769px) {
        .header .mnu a {
          padding: 0.5rem 0;
          color: #fff; }
          .header .mnu a.active {
            color: #aaa !important;
            font-weight: 600; } }
      .header .mnu a:hover, .header .mnu a.active {
        color: #fbbc0f; }
    .header .mnu:last-child a {
      padding-right: 0; }
  .header #mnu-Search {
    background: url(../images/bkg/ofera-lupa.png) no-repeat right center;
    width: 30px;
    height: auto; }
    .header #mnu-Search a {
      font-size: 0; }
  .header .site-logo {
    align-items: center;
    border: 0;
    display: flex;
    font-size: 0;
    justify-content: center;
    width: 13%;
    margin-right: 2rem; }
    @media all and (max-width: 769px) {
      .header .site-logo {
        width: 40%; } }
    .header .site-logo:hover {
      color: #fff;
      transition: none; }
  .header .site-logo-link {
    border: 0;
    display: block;
    font-size: 0;
    width: 139px;
    height: 35px;
    padding: 0;
    background-position: left; }
  .header .main-menu {
    display: flex;
    flex: 1 1;
    justify-content: flex-end;
    width: 30.6875rem; }
    @media all and (max-width: 769px) {
      .header .main-menu {
        left: 0;
        position: absolute;
        top: 0;
        width: 100%; } }
    .header .main-menu .menu-content {
      transition: all 0.55s ease-in-out;
      z-index: 5; }
      @media all and (max-width: 769px) {
        .header .main-menu .menu-content {
          left: -100vh; } }
    .header .main-menu.active .menu-content {
      background: #000;
      left: 0;
      opacity: 1;
      pointer-events: all;
      text-align: center;
      top: 4.6rem;
      transform: translate(0, 0);
      width: 100%; }
    .header .main-menu.active .menu-mobile {
      background: url(../images/bkg/menu.png) no-repeat center;
      background-size: contain; }

.menu-mobile {
  background: url(../images/bkg/menu.png) no-repeat center;
  background-size: contain;
  margin-top: 0 !important; }

.header-bntns {
  display: flex;
  column-gap: 1rem; }

.subheader {
  height: 32px;
  background: #000; }
  .subheader .content {
    margin: auto;
    display: flex;
    justify-content: flex-end;
    max-width: 1100px; }
    .subheader .content a {
      font-size: 14px;
      color: #fff;
      text-transform: uppercase;
      border-right: 1px solid #fbbc0f;
      margin-right: 0.5rem;
      padding-right: 0.5rem; }
      .subheader .content a:last-of-type {
        border-right: none;
        margin-right: 0;
        padding-right: 0; }
      .subheader .content a:hover {
        text-decoration: none; }

.menu-mobile {
  cursor: pointer;
  color: #000;
  margin-top: 2.5rem;
  padding: 0;
  position: absolute;
  right: 2rem;
  text-align: center;
  top: 1rem;
  width: 2.5rem;
  height: 2.5rem; }
  .menu-mobile::before {
    display: none;
    padding: 0;
    font-size: 0; }
    @media all and (max-width: 769px) {
      .menu-mobile::before {
        display: block; } }

.wrapper-footer {
  padding-bottom: 1.1875rem;
  padding-top: 1.1875rem;
  position: relative; }

.phone,
.email {
  line-height: 1rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 800;
  letter-spacing: -0.44px;
  line-height: 20px; }

.copyright {
  border-top: 1px solid #00e1ff;
  padding-top: 0.5rem; }
  .copyright p {
    color: #fff;
    font-size: 0.75rem;
    font-weight: 800;
    line-height: 0.75rem;
    font-weight: 500;
    letter-spacing: -0.33px;
    line-height: 14px;
    margin: 0; }
    @media all and (max-width: 575px) {
      .copyright p {
        padding: 0.5rem;
        text-align: center; } }

.footer-logo {
  display: none; }

.foot-firt-trg {
  margin-top: 3rem; }
  .foot-firt-trg .foot-line-twoe {
    display: flex;
    justify-content: space-between; }
    .foot-firt-trg .foot-line-twoe p {
      margin: 0;
      color: #fff; }
      .foot-firt-trg .foot-line-twoe p span {
        color: #fbbc0f; }
    .foot-firt-trg .foot-line-twoe a {
      color: #fff;
      text-transform: uppercase; }
    .foot-firt-trg .foot-line-twoe span.yellow-line {
      color: #fbbc0f;
      margin: 0 0.3rem; }

.footer-logo-link {
  height: 6rem;
  width: 14rem;
  width: 113px; }

.footer-sm .ico {
  text-align: center;
  color: #fff;
  margin: 0;
  height: auto; }
  .footer-sm .ico::before {
    padding: 10px;
    font-size: 18px !important;
    color: #fff;
    background: transparent;
    margin: 0 0.5rem;
    border: 2px solid #fff; }
  .footer-sm .ico:hover::before {
    color: #fbbc0f;
    border: 2px solid #fff; }

.footer-sm:last-of-type {
  margin-right: 0; }

.social-icons {
  text-align: left;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 2rem; }

.f-derechos {
  margin-top: 0;
  padding-left: 1rem; }

footer .foot-line-one {
  display: flex;
  column-gap: 2rem; }
  footer .foot-line-one p {
    margin: 0;
    font-size: 12px !important; }

footer .p-social:hover {
  color: #000; }

footer .footer-sm:hover .p-social {
  color: #000; }

footer:before, footer:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 467px;
  height: 270px;
  background-size: 146px 90px;
  background-position: 0px 169px;
  background-repeat: repeat-x;
  z-index: -1; }

footer:after {
  background-size: 146px 90px;
  background-position: 73px -64px;
  background-repeat: repeat-x;
  bottom: 264px;
  z-index: -1; }

footer {
  padding-top: 3rem; }

.footer {
  position: relative;
  background: black; }
  .footer .row {
    justify-content: space-around;
    align-items: center; }
    @media all and (max-width: 767px) {
      .footer .row {
        align-items: center;
        flex-direction: column;
        justify-content: center; } }
  .footer .footer-grid {
    display: flex;
    column-gap: 2rem; }
    .footer .footer-grid div {
      flex: 1; }
      .footer .footer-grid div h2 {
        font-size: 19px;
        color: #fbbc0f;
        font-weight: 800 !important; }
      .footer .footer-grid div p {
        color: #fff;
        font-size: 15px;
        min-height: 180px;
        text-align: justify; }
        .footer .footer-grid div p span {
          color: #fbbc0f; }
      .footer .footer-grid div a {
        color: #fff; }
        .footer .footer-grid div a:hover {
          color: #fbbc0f; }

.contact-f {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  @media all and (max-width: 575px) {
    .contact-f {
      flex-direction: column; } }

.footer-sm-fb.ico {
  color: #000;
  border: 2px solid #000;
  margin-right: 0.5rem; }

.footer-sm-tw.ico {
  color: #000;
  border: 2px solid #000;
  margin-right: 0.5rem; }

.footer-sm-in.ico {
  color: #000;
  border: 2px solid #000;
  margin-right: 0.5rem; }

.footer-sm-ins.ico {
  color: #000;
  border: 2px solid #000;
  margin-right: 0.5rem; }

.footer-sm-yt.ico {
  color: #ed1c24; }

.link-privacidad {
  margin-right: 4rem;
  line-height: 40px;
  letter-spacing: -0.33px; }
  @media all and (max-width: 769px) {
    .link-privacidad {
      margin-left: auto;
      margin: 0 auto 1rem;
      display: block; } }
  .link-privacidad:hover {
    line-height: 40px; }
  .link-privacidad:last-of-type {
    margin-right: 0; }

.sec-home-top p,
.sec-como-top p,
.sec-somos-top p,
.sec-contact-main p {
  opacity: 1;
  line-height: 25px; }

.sec-home-top .container-fluid {
  background: url("../images/bkg/yellow-home.png") no-repeat right center;
  background-size: cover !important;
  background-position: right top;
  min-height: 655px;
  max-width: 100%; }
  .sec-home-top .container-fluid .content {
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    height: 655px;
    align-items: flex-end;
    text-align: right; }
  @media all and (max-width: 575px) {
    .sec-home-top .container-fluid {
      min-height: auto; } }
  @media all and (max-width: 1247px) {
    .sec-home-top .container-fluid {
      background-size: 100%; } }
  @media all and (max-width: 991px) {
    .sec-home-top .container-fluid {
      background-size: 50%; } }

@media all and (max-width: 991px) {
  .sec-home-top .row {
    align-items: center;
    flex-direction: column; } }

.sec-procesos-top .container-fluid {
  background: url("../images/bkg/yellow-somos-pg.jpg") no-repeat right center;
  background-size: cover !important;
  background-position: right top;
  min-height: 655px;
  max-width: 100%; }
  .sec-procesos-top .container-fluid .content {
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    height: 655px;
    align-items: flex-end;
    text-align: right; }
  @media all and (max-width: 575px) {
    .sec-procesos-top .container-fluid {
      min-height: auto; } }
  @media all and (max-width: 1247px) {
    .sec-procesos-top .container-fluid {
      background-size: 100%; } }
  @media all and (max-width: 991px) {
    .sec-procesos-top .container-fluid {
      background-size: 50%; } }

@media all and (max-width: 991px) {
  .sec-procesos-top .row {
    align-items: center;
    flex-direction: column; } }

.sec-equipos-top .container-fluid {
  background: url("../images/bkg/yellow-equipos-pg.png") no-repeat right center;
  background-size: cover !important;
  background-position: right top;
  min-height: 655px;
  max-width: 100%; }
  .sec-equipos-top .container-fluid .content {
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    height: 655px;
    align-items: flex-end;
    text-align: right; }
  @media all and (max-width: 575px) {
    .sec-equipos-top .container-fluid {
      min-height: auto; } }
  @media all and (max-width: 1247px) {
    .sec-equipos-top .container-fluid {
      background-size: 100%; } }
  @media all and (max-width: 991px) {
    .sec-equipos-top .container-fluid {
      background-size: 50%; } }

@media all and (max-width: 991px) {
  .sec-equipos-top .row {
    align-items: center;
    flex-direction: column; } }

.sec-como-top .container-fluid {
  background: url("../images/bkg/yellow-nosotros-pg.jpg") no-repeat right center;
  background-size: cover !important;
  background-position: right top;
  min-height: 655px;
  max-width: 100%; }
  .sec-como-top .container-fluid .content {
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    height: 655px;
    align-items: flex-end;
    text-align: right; }
  @media all and (max-width: 575px) {
    .sec-como-top .container-fluid {
      min-height: auto; } }
  @media all and (max-width: 1247px) {
    .sec-como-top .container-fluid {
      background-size: 100%; } }
  @media all and (max-width: 991px) {
    .sec-como-top .container-fluid {
      background-size: 50%; } }

@media all and (max-width: 991px) {
  .sec-como-top .row {
    align-items: center;
    flex-direction: column; } }

.sec-osomos-top .container-fluid {
  background: url("../images/bkg/int-nosotros.jpg") no-repeat right center;
  background-size: cover !important;
  background-position: right top;
  min-height: 655px;
  max-width: 100%; }
  .sec-osomos-top .container-fluid .content {
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    height: 655px;
    align-items: flex-end;
    text-align: right; }
  @media all and (max-width: 575px) {
    .sec-osomos-top .container-fluid {
      min-height: auto; } }
  @media all and (max-width: 1247px) {
    .sec-osomos-top .container-fluid {
      background-size: 100%; } }
  @media all and (max-width: 991px) {
    .sec-osomos-top .container-fluid {
      background-size: 50%; } }

@media all and (max-width: 991px) {
  .sec-osomos-top .row {
    align-items: center;
    flex-direction: column; } }

.sec-contact-main .container-fluid {
  background: url("../images/bkg/ofera-contact.jpg") no-repeat right center;
  background-size: cover !important;
  background-position: right top;
  min-height: 655px;
  max-width: 100%; }
  .sec-contact-main .container-fluid .content {
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    height: 655px;
    align-items: flex-end;
    text-align: right; }
  @media all and (max-width: 575px) {
    .sec-contact-main .container-fluid {
      min-height: auto; } }
  @media all and (max-width: 1247px) {
    .sec-contact-main .container-fluid {
      background-size: 100%; } }
  @media all and (max-width: 991px) {
    .sec-contact-main .container-fluid {
      background-size: 50%; } }

@media all and (max-width: 991px) {
  .sec-contact-main .row {
    align-items: center;
    flex-direction: column; } }

.sec-contact-main .container-fluid {
  background-position: center !important; }

.sec-contact-top .container-fluid {
  background: url("../images/bkg/yellow-contact.png") no-repeat right center;
  background-size: cover !important;
  background-position: right top;
  min-height: 655px;
  max-width: 100%; }
  .sec-contact-top .container-fluid .content {
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    height: 655px;
    align-items: flex-end;
    text-align: right; }
  @media all and (max-width: 575px) {
    .sec-contact-top .container-fluid {
      min-height: auto; } }
  @media all and (max-width: 1247px) {
    .sec-contact-top .container-fluid {
      background-size: 100%; } }
  @media all and (max-width: 991px) {
    .sec-contact-top .container-fluid {
      background-size: 50%; } }

@media all and (max-width: 991px) {
  .sec-contact-top .row {
    align-items: center;
    flex-direction: column; } }

.sec-contact-top .container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.custom-col {
  max-width: 1100px;
  flex: 1; }

.sec-conexiones {
  margin-top: 4.3rem;
  overflow: hidden;
  padding: 0 2.5625rem 0; }
  .sec-conexiones .sec-subtitle {
    color: #001769;
    font-size: 2.5rem;
    font-family: "Open Sans", sans-serif;
    line-height: 2.5rem;
    font-weight: 800;
    letter-spacing: -1.11px;
    margin-bottom: 4.7rem;
    text-align: center;
    text-transform: uppercase; }
  .sec-conexiones .us-desc {
    text-align: left;
    position: absolute;
    top: 410px;
    padding: 0 10px; }
    .sec-conexiones .us-desc .subtitle-line {
      margin-bottom: 0;
      padding-bottom: 0;
      line-height: 0.9; }
    .sec-conexiones .us-desc p {
      font-size: 21px; }
    @media all and (max-width: 575px) {
      .sec-conexiones .us-desc {
        position: initial; } }
  .sec-conexiones .us-icons {
    margin-top: 120px; }
    @media all and (max-width: 575px) {
      .sec-conexiones .us-icons .d-flex div {
        margin-top: 2rem; } }

.quienes-text {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  column-gap: 1rem; }
  .quienes-text .quien-desc {
    width: 50%;
    padding: 2rem 0 0;
    flex: 1; }
    .quienes-text .quien-desc h2 span {
      color: #fbbc0f; }
  .quienes-text .quien-aux {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }

.quienes-info {
  display: flex;
  column-gap: 3rem;
  background: #fff;
  padding: 2.4rem 2.85rem;
  box-shadow: 3px -1px 15px 0px rgba(119, 119, 119, 0.49);
  -webkit-box-shadow: 3px -1px 15px 0px rgba(119, 119, 119, 0.49);
  -moz-box-shadow: 3px -1px 15px 0px rgba(119, 119, 119, 0.49);
  width: fit-content;
  margin-top: 4rem;
  margin-bottom: 1rem; }
  .quienes-info div {
    align-items: center;
    display: flex;
    column-gap: 1rem; }
  .quienes-info p {
    margin: 0;
    font-size: 14px;
    line-height: 1.2; }
    .quienes-info p small {
      font-size: 16px; }

.right-header {
  width: 532px; }

.mivi-reg .mivi-text {
  width: 50%;
  padding: 2rem 0; }

.wrapper-animation {
  justify-content: center;
  margin: 0 auto 3.7rem auto; }
  .wrapper-animation .d-flex {
    justify-content: center;
    position: relative;
    align-items: flex-end;
    justify-content: space-around; }
  .wrapper-animation h5 {
    line-height: 1;
    font-weight: 600;
    font-size: 38px;
    letter-spacing: -0.92px;
    order: 2;
    padding-top: 1rem;
    text-align: center;
    text-transform: uppercase; }

.mv-and {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-duration: 1s;
  animation-fill-mode: both;
  bottom: 2000px;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1; }
  .mv-and svg {
    height: 11.5625rem !important;
    margin: auto;
    width: 11.5625rem !important; }
  .mv-and:nth-child(1) {
    padding-right: 3.3rem; }
    @media all and (max-width: 575px) {
      .mv-and:nth-child(1) {
        padding-right: 0; } }
  .mv-and:nth-child(2) {
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
    padding-right: 3.3rem; }
    @media all and (max-width: 575px) {
      .mv-and:nth-child(2) {
        margin: 2rem auto;
        padding-right: 0; } }
  .mv-and:nth-child(3) {
    -webkit-animation-delay: 1.4s;
    animation-delay: 1.4s; }
  .mv-and.on-item {
    -webkit-animation-name: bounce-in-up;
    animation-name: bounce-in-up;
    bottom: 0;
    position: relative; }
    .mv-and.on-item:nth-child(1)::after {
      -webkit-animation: 1.3s ease 0s normal forwards 1 fadein;
      animation: 1.3s ease 0s normal forwards 1 fadein;
      opacity: 1; }
    .mv-and.on-item:nth-child(2)::after {
      -webkit-animation: 1.7s ease 0s normal forwards 1 fadein;
      animation: 1.7s ease 0s normal forwards 1 fadein;
      opacity: 1; }

.sec-servicios {
  overflow: hidden;
  background: #fff; }
  .sec-servicios .col-lg-3 {
    min-height: 360px;
    display: flex;
    align-items: flex-end;
    padding: 1rem; }
    .sec-servicios .col-lg-3.h-one {
      background: url(../images/bkg/ofera-back-1.jpg) no-repeat;
      background-size: cover; }
    .sec-servicios .col-lg-3.h-two {
      background: url(../images/bkg/ofera-back-2.jpg) no-repeat;
      background-size: cover; }
    .sec-servicios .col-lg-3.h-three {
      background: url(../images/bkg/ofera-back-3.jpg) no-repeat;
      background-size: cover; }
    .sec-servicios .col-lg-3.h-four {
      background: url(../images/bkg/ofera-back-4.jpg) no-repeat;
      background-size: cover; }
    .sec-servicios .col-lg-3 p {
      color: #fff;
      text-align: left;
      font-size: 25px;
      font-weight: 600; }
    .sec-servicios .col-lg-3 a {
      color: #fff;
      text-align: right;
      width: 100%;
      display: block;
      font-size: 1.25rem;
      font-weight: 100;
      padding-right: 3rem;
      background: url(../images/bkg/arrow-r.png) no-repeat right center; }
  .sec-servicios .col-lg-6.back-img {
    background: url(../images/bkg/fedi-servicios.jpg) no-repeat center bottom;
    background-size: cover; }
  .sec-servicios .container-fluid {
    max-width: 100%;
    margin: 0;
    padding: 0;
    width: 100%; }
  .sec-servicios .sec-subtitle {
    color: #001769;
    font-size: 2.5rem;
    font-family: "Open Sans", sans-serif;
    line-height: 2.5rem;
    font-weight: 800;
    letter-spacing: -1.11px;
    text-align: center;
    text-transform: uppercase; }
  .sec-servicios .row {
    padding-top: 1.8rem;
    position: relative; }
  .sec-servicios h3 {
    color: #20c997;
    font-size: 1.875rem;
    font-family: "Open Sans", sans-serif;
    line-height: 31px;
    padding: 1rem;
    border: 1px solid #20c997;
    font-weight: 800;
    background: url(../images/bkg/ondas-title-pink.svg) no-repeat center bottom;
    display: inline-flex;
    letter-spacing: -0.83px;
    line-height: 38px;
    margin-bottom: 0.8rem;
    padding-bottom: 1.28rem;
    text-align: center;
    text-transform: uppercase; }
    @media all and (max-width: 575px) {
      .sec-servicios h3 {
        font-size: 24px;
        line-height: 25px; } }
  .sec-servicios p {
    color: #2f2f2f;
    font-size: 1.0625rem;
    font-weight: 500;
    line-height: 1.0625rem;
    letter-spacing: -0.5px;
    line-height: 21px;
    margin: 0 auto 0.5rem;
    position: relative;
    text-align: center;
    z-index: 5; }
  .sec-servicios .button {
    margin-top: 1.5rem; }

.sec-testimonios {
  background: #f0f0f0;
  height: 456px;
  margin-top: 4.5rem;
  padding-top: 4.375rem;
  position: relative; }
  .sec-testimonios::before {
    background: #79cbed;
    border-radius: 50%;
    content: " ";
    display: block;
    height: 9.5rem;
    position: absolute;
    top: 50%;
    width: 9.5rem;
    left: 0;
    transform: translate(-50%, -50%); }
  .sec-testimonios::after {
    background: #79cbed;
    border-radius: 50%;
    content: " ";
    display: block;
    height: 9.5rem;
    position: absolute;
    top: 50%;
    width: 9.5rem;
    right: 0;
    transform: translate(50%, -50%); }
  .sec-testimonios .swiper-pagination {
    bottom: 90px;
    pointer-events: fill; }
  .sec-testimonios .container-fluid {
    height: 100%; }
  .sec-testimonios .swiper-button-prev,
  .sec-testimonios .swiper-button-next {
    display: none; }
  .sec-testimonios .swiper-container {
    height: 456px;
    pointer-events: none; }
  .sec-testimonios .swiper-pagination-bullet {
    background: #f81676;
    border: 0;
    height: 0.7rem;
    margin: 0.3rem;
    opacity: 0.8;
    width: 0.7rem; }
    .sec-testimonios .swiper-pagination-bullet.swiper-pagination-bullet-active  {
      opacity: 1; }
  .sec-testimonios h3 {
    color: #0275d8;
    font-size: 3.125rem;
    font-family: "Open Sans", sans-serif;
    line-height: 3.6rem;
    padding: 0.5rem 1rem 1rem;
    position: relative;
    display: inline-flex;
    font-weight: 900;
    letter-spacing: -1.39px;
    line-height: 63px;
    margin: 0 auto 2.5rem;
    text-align: center;
    text-transform: uppercase;
    word-break: break-all; }
  .sec-testimonios p {
    line-height: 1rem;
    color: #2f2f2f;
    font-size: 1rem;
    border-bottom: solid 1px #00e6e0;
    font-weight: 500;
    letter-spacing: -0.47px;
    margin-bottom: 1.3rem;
    padding-bottom: 1.5625rem;
    width: 60.5%; }
    @media all and (max-width: 767px) {
      .sec-testimonios p {
        width: 100%; } }
  .sec-testimonios h6 {
    line-height: 1rem;
    color: #2f2f2f;
    font-size: 1rem;
    font-style: italic; }

.sec-como-funciona .sec-clientes {
  padding: 4rem 0;
  min-height: 12rem;
  min-height: 540px;
  margin: 0; }
  .sec-como-funciona .sec-clientes p {
    font-size: 20px;
    text-align: left;
    line-height: 1.2;
    margin-top: 2rem; }

.sec-como-funciona .sec-internet.alt .sec-title {
  color: #2e3746;
  font-weight: normal;
  display: inline-flex;
  margin-bottom: 3rem;
  font-family: 'Montserrat', sans-serif; }

.sec-como-funciona .sec-internet.alt .pro-list {
  margin: 4rem 0;
  min-height: 12rem;
  background: url(../images/bkg/muve-obj.jpg) left/no-repeat;
  background-size: 45%;
  min-height: 540px; }
  .sec-como-funciona .sec-internet.alt .pro-list .content-alt {
    display: flex;
    max-width: 1100px;
    width: 1100px;
    margin: auto; }
    .sec-como-funciona .sec-internet.alt .pro-list .content-alt .quien-aux,
    .sec-como-funciona .sec-internet.alt .pro-list .content-alt .quien-desc {
      flex: 1; }
      .sec-como-funciona .sec-internet.alt .pro-list .content-alt .quien-aux ul,
      .sec-como-funciona .sec-internet.alt .pro-list .content-alt .quien-desc ul {
        padding-left: 0; }

.content-alt {
  max-width: 1100px;
  width: 1100px;
  margin: auto; }
  .content-alt.mivision {
    display: flex;
    column-gap: 2rem;
    align-items: flex-start; }
    .content-alt.mivision div {
      flex: 1; }
      .content-alt.mivision div h4 {
        font-size: 30px;
        text-transform: uppercase;
        color: #2e3746;
        font-family: 'Montserrat', sans-serif; }

.more-link {
  color: #000;
  font-size: 18px;
  text-transform: uppercase; }
  .more-link:hover {
    text-decoration: none;
    color: #fbbc0f !important; }

.sec-home .sec-conexiones {
  padding: 5rem 0;
  min-height: 12rem;
  background: #fff;
  margin: 0; }

.sec-home .sec-internet {
  padding: 6rem 0;
  min-height: 12rem;
  background: url(../images/bkg/yellow-quees.png) left/no-repeat;
  background-size: cover;
  margin: 0; }
  .sec-home .sec-internet .more-link {
    color: #fff; }

.sec-home .sec-page-top {
  padding: 0; }

.sec-home .grid-procesos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }
  .sec-home .grid-procesos div {
    padding: 8rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-shadow: 1px -31px 62px -9px rgba(0, 0, 0, 0.81) inset;
    -webkit-box-shadow: 1px -31px 62px -9px rgba(0, 0, 0, 0.81) inset;
    -moz-box-shadow: 1px -31px 62px -9px rgba(0, 0, 0, 0.81) inset;
    padding: 11rem 1rem 1rem;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important; }
    .sec-home .grid-procesos div h1 {
      font-size: 32px;
      color: #fbbc0f;
      font-weight: 800; }

.sec-home .sec-promosiones .grid-valores {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }
  .sec-home .sec-promosiones .grid-valores div {
    background: #faf5e6;
    padding: 8rem 1rem 1rem; }
    .sec-home .sec-promosiones .grid-valores div span img {
      max-height: 12px;
      min-height: 12px; }
    .sec-home .sec-promosiones .grid-valores div img {
      max-height: 94px;
      min-height: 94px; }
    .sec-home .sec-promosiones .grid-valores div.grid-odd {
      background: #fbbc0f; }
  .sec-home .sec-promosiones .grid-valores h4 {
    text-transform: uppercase;
    font-size: 30px;
    color: #000;
    font-weight: 100;
    margin: 1rem 0 1.5rem;
    font-family: 'Montserrat', sans-serif; }
  .sec-home .sec-promosiones .grid-valores p {
    font-size: 20px; }

.soluciones-reg {
  text-align: center; }
  .soluciones-reg .solu-title {
    width: 673px;
    margin: auto; }
  .soluciones-reg .sec-title {
    font-size: 36px;
    text-transform: uppercase;
    font-weight: 400;
    color: #fff;
    font-family: 'Montserrat', sans-serif; }
  .soluciones-reg .soluciones-icons {
    display: flex;
    justify-content: space-between;
    margin-top: 6rem;
    align-items: flex-end; }
    .soluciones-reg .soluciones-icons p {
      color: #259d00;
      text-transform: uppercase;
      font-size: 24px;
      font-family: 'Montserrat', sans-serif;
      margin-top: 3rem; }

.accordion-button {
  transform: none; }
  .accordion-button:hover, .accordion-button:focus {
    transform: none; }

.whats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem 0;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  z-index: 999; }

.whats img {
  width: 80px; }

.onload .contact-form::after {
  content: " ";
  background: url(../images/bkg/ajax-loader.gif) no-repeat center center;
  display: block;
  height: 40px;
  width: 40px; }

.content-form {
  padding: 3rem 0;
  display: flex;
  column-gap: 4rem; }
  .content-form textarea {
    height: 4.8125rem; }

.msg-succes {
  color: #259d00;
  font-size: 1.75rem;
  font-weight: 800;
  line-height: 1.75rem;
  font-weight: 800;
  letter-spacing: -0.5px;
  margin: 2rem auto;
  text-align: center;
  bottom: 0;
  border: 1px solid #259d00;
  padding: 1rem; }

.title-contact {
  color: #0275d8;
  font-size: 3.125rem;
  font-family: "Open Sans", sans-serif;
  line-height: 3.6rem;
  font-weight: 900;
  letter-spacing: -1.39px;
  line-height: 63px;
  margin: auto;
  padding: 0.7rem 0;
  text-transform: uppercase;
  word-break: break-all; }
  .title-contact::before {
    background: #faf5e6;
    content: " ";
    display: block;
    height: 8px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 75px; }
  .title-contact::after {
    background: #faf5e6;
    bottom: 0;
    content: " ";
    display: block;
    height: 8px;
    left: 0;
    position: absolute;
    right: 0;
    width: 75px; }

.subtitle-contact {
  color: #fff;
  font-size: 1.75rem;
  font-weight: 800;
  line-height: 1.75rem;
  font-weight: 800;
  letter-spacing: -0.75px;
  margin: 2.3rem auto 0.5rem auto;
  position: relative;
  text-transform: uppercase;
  z-index: 10; }

.text-contact {
  text-align: center;
  color: #259d00;
  font-size: 3.125rem;
  font-family: "Open Sans", sans-serif;
  line-height: 3.6rem;
  font-weight: 800;
  letter-spacing: -0.5px;
  margin: 0 auto 1.2rem; }

.contact-top {
  font-weight: normal;
  color: #000;
  flex: 1; }
  .contact-top p {
    font-size: 16px;
    margin: 0; }
  .contact-top h2 {
    font-size: 20px !important; }
  .contact-top .caption-contact {
    font-size: 20px; }

.contact-form {
  flex: 1; }
  @media all and (min-width: 769px) {
    .contact-form {
      width: 40%; } }
  @media all and (max-width: 991px) {
    .contact-form {
      width: 358px;
      margin-left: auto;
      margin-right: auto; } }
  @media all and (max-width: 575px) {
    .contact-form {
      width: 100%; } }
  .contact-form input:not([type="radio"]):not([type="checkbox"]),
  .contact-form select {
    border-bottom: 2px solid #000; }
  .contact-form input:not([type="radio"]):not([type="checkbox"]),
  .contact-form select,
  .contact-form textarea {
    margin-bottom: 2rem; }
  .contact-form input:not([type="radio"]):not([type="checkbox"]),
  .contact-form select,
  .contact-form textarea {
    opacity: 0.7;
    color: #000; }
    .contact-form input:not([type="radio"]):not([type="checkbox"]):focus,
    .contact-form select:focus,
    .contact-form textarea:focus {
      outline: none;
      background: transparent;
      color: #000; }
  .contact-form textarea {
    border: 2px solid #000;
    min-height: 207px; }
    .contact-form textarea:focus {
      outline: none; }
  .contact-form input:placeholder-shown:not(:focus) + label {
    margin-left: 10px;
    color: #000; }
  .contact-form textarea:placeholder-shown:not(:focus) + label {
    margin-left: 10px;
    color: #000; }

.form-content {
  order: 2; }

.mapouter,
.gmap_canvas,
#gmap_canvas {
  width: 100%; }

.sec-contacto {
  position: relative; }
  .sec-contacto .row {
    display: flex;
    justify-content: flex-start !important; }
    .sec-contacto .row .form-field {
      padding: 0 !important; }
  .sec-contacto .form-content {
    opacity: 1;
    background: transparent;
    padding: 0; }
  .sec-contacto input:not([type="radio"]):not([type="checkbox"]) + label,
  .sec-contacto select + label,
  .sec-contacto textarea + label {
    top: -1.5rem;
    color: #000 !important;
    font-family: "Montserrat", sans-serif;
    margin-left: 0; }
  .sec-contacto input:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus) + label,
  .sec-contacto select.invalid:not(:focus) + label,
  .sec-contacto select:invalid:not(:focus) + label,
  .sec-contacto textarea:placeholder-shown:not(:focus) + label {
    font-size: 20px; }
  .sec-contacto input:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus) + label,
  .sec-contacto textarea:placeholder-shown:not(:focus) + label {
    font-weight: 100; }
  .sec-contacto textarea,
  .sec-contacto input,
  .sec-contacto label {
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    letter-spacing: -0.19px;
    line-height: 24px; }
  .sec-contacto .buttons .button {
    padding: 10px 50px;
    color: #fff;
    font-size: 18px;
    background: #000; }
    .sec-contacto .buttons .button:hover {
      background: transparent;
      border: 2px solid #000;
      color: #000; }

input[type="checkbox"] + label,
input[type="radio"] + label {
  color: #000;
  font-size: 16px; }

.sec-contacto .form-content {
  display: flex; }

.contact-background {
  box-shadow: 0px 90px 32px 0px rgba(0, 0, 0, 0.61) inset;
  -webkit-box-shadow: 0px 90px 32px 0px rgba(0, 0, 0, 0.61) inset;
  -moz-box-shadow: 0px 90px 32px 0px rgba(0, 0, 0, 0.61) inset;
  position: absolute;
  width: 100%;
  height: 125px;
  z-index: 555; }

@media all and (max-width: 575px) {
  .contact-top {
    width: 100%; } }

.form-field.sectitle:nth-child(4n) {
  color: #000c56;
  font-weight: 800;
  text-transform: uppercase;
  margin-top: 40px; }

.form-field.sectitle:nth-child(5n) {
  margin-bottom: 30px; }

.sec-cont .sec-contact-top {
  background-size: cover;
  min-height: 400px; }
  .sec-cont .sec-contact-top .container-fluid {
    max-width: 100%; }

.sec-somos-top .container-fluid {
  background: url("../images/bkg/andari-somo-top2.jpg") no-repeat right center;
  background-size: cover !important;
  background-position: right top;
  min-height: 655px;
  max-width: 100%; }
  .sec-somos-top .container-fluid .content {
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    height: 655px;
    align-items: flex-end;
    text-align: right; }
  @media all and (max-width: 575px) {
    .sec-somos-top .container-fluid {
      min-height: auto; } }
  @media all and (max-width: 1247px) {
    .sec-somos-top .container-fluid {
      background-size: 100%; } }
  @media all and (max-width: 991px) {
    .sec-somos-top .container-fluid {
      background-size: 50%; } }

@media all and (max-width: 991px) {
  .sec-somos-top .row {
    align-items: center;
    flex-direction: column; } }

.pag-somos .sec-page-top p {
  margin-left: 0;
  width: 26.5rem; }
  @media all and (max-width: 575px) {
    .pag-somos .sec-page-top p {
      width: 100%; } }

.accordion-item {
  border: 0 !important; }

.accordion-button {
  padding: 0 !important;
  background: transparent;
  width: auto !important; }
  .accordion-button::after {
    content: none !important; }
  .accordion-button.collapsed .btn-plus {
    display: block; }
  .accordion-button.collapsed .btn-minus {
    display: none; }
  .accordion-button .btn-plus {
    display: none; }
  .accordion-button .btn-minus {
    display: block; }

.sec-vision {
  margin-top: 4.4rem; }
  .sec-vision p {
    color: #2f2f2f;
    font-size: 1.0625rem;
    font-weight: 500;
    line-height: 1.0625rem;
    letter-spacing: -0.5px;
    line-height: 21px;
    text-align: center; }

.sec-valores {
  margin: 8.125rem 0 7.625rem; }
  .sec-valores ul {
    margin: auto; }
    @media all and (max-width: 991px) {
      .sec-valores ul {
        width: 50%; } }
    @media all and (max-width: 767px) {
      .sec-valores ul {
        width: 100%; } }
  .sec-valores li {
    bottom: -1000px;
    position: relative;
    transform: translate(0, 0); }
    .sec-valores li.on-item:nth-child(1) {
      bottom: 0;
      transition: all 0.7s ease-in-out; }
    .sec-valores li.on-item:nth-child(2) {
      bottom: 0;
      transition: all 1.4s ease-in-out; }
    .sec-valores li.on-item:nth-child(3) {
      bottom: 0;
      transition: all 2.1s ease-in-out; }
    .sec-valores li.on-item:nth-child(4) {
      bottom: 0;
      transition: all 2.8s ease-in-out; }

.sec-candidatos-top .container-fluid {
  background: url("../images/bkg/andari-top-candidatos-2.jpg") no-repeat right center;
  background-size: cover !important;
  background-position: right top;
  min-height: 655px;
  max-width: 100%; }
  .sec-candidatos-top .container-fluid .content {
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    height: 655px;
    align-items: flex-end;
    text-align: right; }
  @media all and (max-width: 575px) {
    .sec-candidatos-top .container-fluid {
      min-height: auto; } }
  @media all and (max-width: 1247px) {
    .sec-candidatos-top .container-fluid {
      background-size: 100%; } }
  @media all and (max-width: 991px) {
    .sec-candidatos-top .container-fluid {
      background-size: 50%; } }

@media all and (max-width: 991px) {
  .sec-candidatos-top .row {
    align-items: center;
    flex-direction: column; } }

.pag-candidatos .intro-default h2 {
  margin-bottom: 1rem; }

.pag-candidatos .sec-page-top h4 {
  width: 80%; }
  @media all and (max-width: 575px) {
    .pag-candidatos .sec-page-top h4 {
      width: 100%; } }

.pag-candidatos .sec-page-top p {
  margin-left: 0;
  width: 32.4375rem; }
  @media all and (max-width: 575px) {
    .pag-candidatos .sec-page-top p {
      width: 100%; } }

.sec-mercado-laboral {
  background: #efefef;
  padding: 2.8125rem 0; }
  .sec-mercado-laboral .content-text p:first-of-type {
    background: url(../images/bkg/linea2-blue.svg) no-repeat left bottom;
    padding-bottom: 2.2rem; }
  .sec-mercado-laboral .content-text p:last-of-type {
    margin: 0; }

.sec-vidalaboral {
  margin: 5.1875rem 0 7.5rem; }
  .sec-vidalaboral .txt-pink {
    color: #20c997;
    font-size: 1.875rem;
    font-family: "Open Sans", sans-serif;
    line-height: 31px;
    padding: 1rem;
    border: 1px solid #20c997;
    font-weight: 800;
    letter-spacing: -0.94px;
    text-align: center;
    width: 80%; }
    @media all and (max-width: 575px) {
      .sec-vidalaboral .txt-pink {
        font-size: 24px;
        line-height: 25px; } }
  .sec-vidalaboral h3 {
    color: #20c997;
    font-size: 1.875rem;
    font-family: "Open Sans", sans-serif;
    line-height: 31px;
    padding: 1rem;
    border: 1px solid #20c997;
    font-weight: 800;
    font-family: "Open Sans", sans-serif;
    letter-spacing: -0.83px;
    margin: 5rem auto 4rem;
    text-align: center;
    text-transform: uppercase;
    width: 50%; }
    @media all and (max-width: 575px) {
      .sec-vidalaboral h3 {
        font-size: 24px;
        line-height: 25px; } }
  .sec-vidalaboral p {
    color: #2f2f2f;
    font-size: 1.0625rem;
    font-weight: 500;
    line-height: 1.0625rem;
    letter-spacing: -0.5px;
    line-height: 21px;
    margin: 0 auto 0.5rem;
    position: relative;
    text-align: center;
    z-index: 5; }
    .sec-vidalaboral p strong {
      font-weight: 800; }

.sec-candidatos-servicios {
  margin-top: 3.7rem; }
  .sec-candidatos-servicios li:nth-child(1)::before {
    background-size: cover;
    content: " ";
    display: block;
    height: 84px;
    margin-right: 2.5%;
    position: static;
    width: 83px;
    background: url(../images/bkg/servicio-vocacional.svg) no-repeat center; }
    @media all and (max-width: 767px) {
      .sec-candidatos-servicios li:nth-child(1)::before {
        height: 70px;
        width: 70px; } }
  .sec-candidatos-servicios li:nth-child(2)::before {
    background-size: cover;
    content: " ";
    display: block;
    height: 84px;
    margin-right: 2.5%;
    position: static;
    width: 83px;
    background: url(../images/bkg/servicio-empresa-coachingliderazgo.svg) no-repeat center; }
    @media all and (max-width: 767px) {
      .sec-candidatos-servicios li:nth-child(2)::before {
        height: 70px;
        width: 70px; } }
  .sec-candidatos-servicios li:nth-child(3)::before {
    background-size: cover;
    content: " ";
    display: block;
    height: 84px;
    margin-right: 2.5%;
    position: static;
    width: 83px;
    background: url(../images/bkg/servicio-candidatos-coaching.svg) no-repeat center; }
    @media all and (max-width: 767px) {
      .sec-candidatos-servicios li:nth-child(3)::before {
        height: 70px;
        width: 70px; } }
  .sec-candidatos-servicios ul {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center; }

.sec-empresas-top .container-fluid {
  background: url("../images/bkg/fedi-servicios-pg.jpg") no-repeat right center;
  background-size: cover !important;
  background-position: right top;
  min-height: 655px;
  max-width: 100%; }
  .sec-empresas-top .container-fluid .content {
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    height: 655px;
    align-items: flex-end;
    text-align: right; }
  @media all and (max-width: 575px) {
    .sec-empresas-top .container-fluid {
      min-height: auto; } }
  @media all and (max-width: 1247px) {
    .sec-empresas-top .container-fluid {
      background-size: 100%; } }
  @media all and (max-width: 991px) {
    .sec-empresas-top .container-fluid {
      background-size: 50%; } }

@media all and (max-width: 991px) {
  .sec-empresas-top .row {
    align-items: center;
    flex-direction: column; } }

.sec-aduanas-top .container-fluid {
  background: url("../images/bkg/fedi-aduanas.jpg") no-repeat right center;
  background-size: cover !important;
  background-position: right top;
  min-height: 655px;
  max-width: 100%; }
  .sec-aduanas-top .container-fluid .content {
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    height: 655px;
    align-items: flex-end;
    text-align: right; }
  @media all and (max-width: 575px) {
    .sec-aduanas-top .container-fluid {
      min-height: auto; } }
  @media all and (max-width: 1247px) {
    .sec-aduanas-top .container-fluid {
      background-size: 100%; } }
  @media all and (max-width: 991px) {
    .sec-aduanas-top .container-fluid {
      background-size: 50%; } }

@media all and (max-width: 991px) {
  .sec-aduanas-top .row {
    align-items: center;
    flex-direction: column; } }

.pag-empresas .sec-page-top {
  padding: 0; }
  .pag-empresas .sec-page-top p {
    margin-left: 0;
    width: 26.5rem; }
    @media all and (max-width: 575px) {
      .pag-empresas .sec-page-top p {
        width: 100%; } }

.pag-empresas .intro-default {
  padding: 5.375rem 0; }
  .pag-empresas .intro-default p:last-of-type {
    background-image: none; }
  .pag-empresas .intro-default h2 {
    background: url(../images/bkg/linea2-blue.svg) no-repeat left bottom;
    padding-bottom: 2.2rem;
    text-transform: uppercase; }
    @media all and (max-width: 991px) {
      .pag-empresas .intro-default h2 {
        background-position: center bottom; } }

.sec-metodologia .subtitle {
  color: #20c997;
  font-size: 1.875rem;
  font-family: "Open Sans", sans-serif;
  line-height: 31px;
  padding: 1rem;
  border: 1px solid #20c997;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
  letter-spacing: -0.83px;
  margin: 5rem auto 4rem;
  text-align: center;
  text-transform: uppercase;
  width: 50%; }
  @media all and (max-width: 575px) {
    .sec-metodologia .subtitle {
      font-size: 24px;
      line-height: 25px; } }

.empresas-servicios-info li:nth-child(1)::before {
  background-size: cover;
  content: " ";
  display: block;
  height: 84px;
  margin-right: 2.5%;
  position: static;
  width: 83px;
  background: url(../images/bkg/servicio-empresa-seleccion.svg) no-repeat center;
  background-size: contain; }
  @media all and (max-width: 767px) {
    .empresas-servicios-info li:nth-child(1)::before {
      height: 70px;
      width: 70px; } }

.empresas-servicios-info li:nth-child(2)::before {
  background-size: cover;
  content: " ";
  display: block;
  height: 84px;
  margin-right: 2.5%;
  position: static;
  width: 83px;
  background: url(../images/bkg/servicio-empresa-nomina.svg) no-repeat center;
  background-size: contain; }
  @media all and (max-width: 767px) {
    .empresas-servicios-info li:nth-child(2)::before {
      height: 70px;
      width: 70px; } }

.empresas-servicios-info li:nth-child(3)::before {
  background-size: cover;
  content: " ";
  display: block;
  height: 84px;
  margin-right: 2.5%;
  position: static;
  width: 83px;
  background: url(../images/bkg/servicio-empresa-coaching.svg) no-repeat center;
  background-size: contain; }
  @media all and (max-width: 767px) {
    .empresas-servicios-info li:nth-child(3)::before {
      height: 70px;
      width: 70px; } }

.empresas-servicios-info li:nth-child(4)::before {
  background-size: cover;
  content: " ";
  display: block;
  height: 84px;
  margin-right: 2.5%;
  position: static;
  width: 83px;
  background: url(../images/bkg/servicio-empresa-outplacement.svg) no-repeat center;
  background-size: contain; }
  @media all and (max-width: 767px) {
    .empresas-servicios-info li:nth-child(4)::before {
      height: 70px;
      width: 70px; } }

/*
 * LAYOUT: SITE HEADER
 * by SalsaLab
 */
.ui-widgetui-datepicker {
  background: #fff; }

.ui-datepicker {
  display: none;
  padding: .2em .2em 0;
  width: 17em; }
  .ui-datepicker .ui-datepicker-header {
    padding: .2em 0;
    position: relative; }
  .ui-datepicker .ui-datepicker-prev,
  .ui-datepicker .ui-datepicker-next {
    height: 1.8em;
    position: absolute;
    top: 2px;
    width: 1.8em; }
    .ui-datepicker .ui-datepicker-prev span,
    .ui-datepicker .ui-datepicker-next span {
      display: block;
      left: 50%;
      margin-left: -8px;
      margin-top: -8px;
      position: absolute;
      top: 50%; }
  .ui-datepicker .ui-datepicker-prev-hover,
  .ui-datepicker .ui-datepicker-next-hover {
    top: 1px; }
  .ui-datepicker .ui-datepicker-prev {
    left: 2px; }
  .ui-datepicker .ui-datepicker-next {
    right: 2px; }
  .ui-datepicker .ui-datepicker-prev-hover {
    left: 1px; }
  .ui-datepicker .ui-datepicker-next-hover {
    right: 1px; }
  .ui-datepicker .ui-datepicker-title {
    line-height: 1.8em;
    margin: 0 2.3em;
    text-align: center; }
    .ui-datepicker .ui-datepicker-title select {
      font-size: 1em;
      margin: 1px 0; }
  .ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
    width: 45%; }
  .ui-datepicker table {
    background: #fff;
    border-collapse: collapse;
    font-size: .9em;
    margin: 0 0 .4em;
    width: 100%; }
  .ui-datepicker th {
    border: 0;
    font-weight: bold;
    padding: .7em .3em;
    text-align: center; }
  .ui-datepicker td {
    border: 0;
    padding: 1px; }
    .ui-datepicker td span,
    .ui-datepicker td a {
      color: #0275d8;
      display: block;
      padding: .2em;
      text-align: right;
      text-decoration: none; }
  .ui-datepicker .ui-datepicker-buttonpane {
    background-image: none;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    margin: .7em 0 0 0;
    padding: 0 .2em; }
    .ui-datepicker .ui-datepicker-buttonpane button {
      cursor: pointer;
      float: right;
      margin: .5em .2em .4em;
      overflow: visible;
      padding: .2em .6em .3em .6em;
      width: auto; }
      .ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
        float: left; }
  .ui-datepicker .ui-icon {
    background-repeat: no-repeat;
    display: block;
    left: .5em;
    overflow: hidden;
    text-indent: -99999px;
    top: .3em; }

.ui-datepicker-row-break {
  clear: both;
  font-size: 0;
  width: 100%; }

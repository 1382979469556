/*
 * SCSS THEME BASIC TAGS File
 * by SalsaLab
 */
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-family: $font-family-base;
}
h2 {
	font-weight: 800;
}
body {
	background: $body-bg;
	display: flex;
	flex-direction: column;
	font-size: 100%;
	font-weight: 300;
	justify-content: space-between;
	min-height: 100vh;
	overflow-x: hidden;
	position: relative;

	color: $light-black;
	font-family: $font-family-base;
	font-size: 21px;
	letter-spacing: 0.23px;
	line-height: 24px;
	outline: none;

	.no-click {
		pointer-events: none;
		cursor: default;
		display: none;
	}
	p,
	h2,
	h3,
	h4 {
		font-family: "Montserrat", sans-serif !important;
		font-weight: normal !important;
		b {
			font-weight: 900 !important;
		}
	}
	h1 {
		font-weight: 900 !important;
		font-family: "Montserrat", sans-serif !important;
	}
}

html {
	font-size: 100%;
	line-height: 16px;
}
$font-basic: $font-family-base !default;

@mixin infinite-anm() {
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

@mixin txt-font-12($color-txt, $fw, $lh: $font-size-12) {
	color: $color-txt;
	font-size: $font-size-12;
	font-weight: $fw;
	line-height: $font-size-12;
}

@mixin txt-font-14($color-txt, $fw, $lh: $font-size-14) {
	color: $color-txt;
	font-size: $font-size-14;
	font-weight: $fw;

	@if $lh {
		line-height: $lh;
	} @else {
		line-height: $font-size-14;
	}
}

@mixin txt-font-16($color-txt, $lh: $font-size-16) {
	@if $lh {
		line-height: $lh;
	} @else {
		line-height: $font-size-16;
	}

	color: $color-txt;
	font-size: $font-size-16;
}

@mixin txt-font-18($color-txt, $fw, $lh: $font-size-18) {
	color: $color-txt;
	font-size: $font-size-18;
	font-weight: $fw;
	line-height: $lh;
}

@mixin txt-font-20($color-txt, $fw, $lh: $font-size-20) {
	@if $lh {
		line-height: $lh;
	} @else {
		line-height: $font-size-18;
	}

	color: $color-txt;
	font-size: $font-size-20;
	font-weight: $fw;
}

@mixin txt-font-25($color-txt, $fw, $lh: $font-size-25) {
	@if $lh {
		line-height: $lh;
	} @else {
		line-height: $font-size-18;
	}

	color: $color-txt;
	font-size: $font-size-25;
	font-weight: $fw;
}

@mixin txt-font-28($color-txt, $fw, $lh: $font-size-28) {
	color: $color-txt;
	font-size: $font-size-28;
	font-weight: $fw;
	line-height: $font-size-28;

	// @media all and (max-width: map-get($grid-breakpoints, "sm")) {
	// 	color: $color-txt; font-size: 1.5rem; line-height: 1.5rem;
	// }
}

@mixin txt-font-30($color-txt, $fw: 400) {
	color: #20c997;
	font-size: $font-size-30;
	font-family: $font-family-heading;
	line-height: 31px;
	padding: 1rem;
	border: 1px solid #20c997;

	@if $fw {
		font-weight: $fw;
	} @else {
		font-weight: 400;
	}

	@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
		font-size: 24px;
		line-height: 25px;
	}
}

@mixin txt-font-40($color-txt, $lh: $font-size-40) {
	color: $color-txt;
	font-size: $font-size-40;
	font-family: $font-family-heading;
	line-height: $font-size-40;
}

@mixin txt-font-45($color-txt) {
	color: $color-txt;
	font-size: 45px;
	font-family: $font-family-heading;
	line-height: 46px;
}

@mixin txt-font-50($color-txt, $lh: $font-size-50) {
	color: $color-txt;
	font-size: $font-size-50;
	font-family: $font-family-heading;
	line-height: 3.6rem;
}

@mixin subtitle($txt-align) {
	@include txt-font-30($section-subtitle);
	font-family: $font-family-heading;
	letter-spacing: -0.83px;
	text-align: $txt-align;
}

.title-f30 {
	h3 {
		@include txt-font-30($section-subtitle, 800);
		font-family: $font-family-heading;
		letter-spacing: -0.83px;
		text-align: center;
		text-transform: uppercase;
	}
}

@mixin p-default() {
	@include txt-font-18($text-gral, 500);
	letter-spacing: -0.5px;
	line-height: 21px;
	text-align: center;
}

.wavy {
	border: 0;
	padding: 2.8rem 10rem;
	height: 30px;
	background: url(#{$_image-path}/bkg/ofera-line-subtitle.svg) no-repeat;
	background-position: center;
}

.container-fluid {
	max-width: 1100px;
}

.button {
	@include txt-font-14($white, 500, 16px);
	letter-spacing: 1.9px;

	&.purple-butt {
		-webkit-box-shadow: 0px 4px 16px -5px rgba(102, 51, 204, 1);
		-moz-box-shadow: 0px 4px 16px -5px rgba(102, 51, 204, 1);
		box-shadow: 0px 4px 16px -5px rgba(102, 51, 204, 1);
		background: $color-primary;
		border: solid 2px $color-primary;
		border-radius: 30px;
		@include txt-font-14($white, 500, 16px);
		letter-spacing: 1.9px;
		&:active,
		&:hover {
			background: transparent;
			border-color: $color-primary;
			color: $color-primary;

			&::after {
				color: $white;
				display: block;
				padding-left: 0.5rem;
			}
		}
	}
	&:active,
	&:hover {
		background: transparent;
		color: $black;
		width: 182px;

		&::after {
			color: $white;
			display: block;
			padding-left: 0.5rem;
		}
	}
}
.button.color-alt {
	background: $color-alt;
	border: solid 2px $color-alt;
	@include txt-font-18($white, 500, 18px);
	&:active,
	&:hover {
		background: transparent;
		border-color: $color-alt;
		color: $color-alt;

		&::after {
			color: $white;
			display: block;
			padding-left: 0.5rem;
		}
	}
}

.bottom-line {
	height: 8px;
	background: $color-primary;
	width: 75px;
	margin-left: 0;
	border: 0;
}

@mixin container-no-max() {
	.container-fluid {
		max-width: 100%;
		padding: 0;
	}
}

@mixin in-project() {
	position: relative;
	z-index: 7;
}

@mixin link-more() {
	font-family: $font-family-light;
	font-size: $font-size-12;
	font-weight: 300;
	line-height: $line-height-14;
	text-decoration: none;
	text-transform: uppercase;
}

@mixin line-subtitle() {
	padding: 0.5rem 1rem 1rem;
	position: relative;
}

@mixin bg-line-primary() {
	background: $black;
}

@mixin circle-lateral() {
	background: $color-secondary;
	border-radius: 50%;
	content: " ";
	display: block;
	height: 9.5rem;
	position: absolute;
	top: 50%;
	width: 9.5rem;
}

.ico-md.p0 {
	&::before {
		padding: 0;
	}
}

.sec-osomos-top {
	h4 {
		border-left: 12px solid $color-secondary;
		padding: 0 1rem !important;
		font-size: 18px !important;
		margin-top: 0 !important;
	}
}

.sec-serv {
	.to-left {
		display: flex;
		justify-content: space-between;
		.the-text {
			background-color: $white;
			padding: 2rem;
			width: 60%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 8rem;
			-webkit-box-shadow: 10px 10px 14px -5px rgba(0, 0, 0, 0.1);
			-moz-box-shadow: 10px 10px 14px -5px rgba(0, 0, 0, 0.1);
			box-shadow: 10px 10px 14px -5px rgba(0, 0, 0, 0.1);
			min-height: 432px;
			h2 {
				padding-bottom: 4rem;
			}
			img {
				margin-top: 14rem;
			}
		}
	}
	&.sec-servicio-left {
		margin: 4rem 0;
		min-height: 12rem;
		background: url(#{$_image-path}/bkg/int-serv-1.jpg) left/no-repeat;
		background-size: 50%;
	}
	&.sec-servicio-two {
		margin: 4rem 0;
		min-height: 12rem;
		background: url(#{$_image-path}/bkg/int-serv-2.jpg) right/no-repeat;
		background-size: 50%;
	}
	&.sec-servicio-three {
		margin: 4rem 0;
		min-height: 12rem;
		background: url(#{$_image-path}/bkg/int-serv-3.jpg) left/no-repeat;
		background-size: 50%;
	}
	&.sec-servicio-four {
		margin: 4rem 0;
		min-height: 12rem;
		background: url(#{$_image-path}/bkg/int-serv-4.jpg) right/no-repeat;
		background-size: 50%;
	}
}

.button {
	@include txt-font-16($white, 24px);
	align-items: center;
	display: inline-flex;
	font-weight: 600;
	justify-content: center;
	min-height: 1.6875rem;
	min-width: 7.668125rem;
	padding: 10px 30px;
	text-shadow: 0 1px 0 0 $white;
	text-transform: uppercase;
}

.bg-dark {
	background: $gray-dark;
}

.bg-primary {
	background: $brand-primary;
}

.txt-center {
	text-align: left;
}

@-webkit-keyframes rightleft {
	0% {
		background-position: 100% 0%;
	}
	50% {
		background-position: 50% 100%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@-moz-keyframes rightleft {
	0% {
		background-position: 100% 0%;
	}
	50% {
		background-position: 50% 100%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes rightleft {
	0% {
		background-position: 100% 0%;
	}
	50% {
		background-position: 50% 100%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@-webkit-keyframes Gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@-moz-keyframes Gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@-webkit-keyframes gradient-bt {
	0% {
		background-position: 0% 0%;
	}
	50% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 0% 100%;
	}
}

@-moz-keyframes gradient-bt {
	0% {
		background-position: 0% 0%;
	}
	50% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 0% 100%;
	}
}

@keyframes gradient-bt {
	0% {
		background-position: 0% 0%;
	}
	50% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 0% 100%;
	}
}

@mixin bg-service-animate($gradient-bt, $sx, $sy) {
	background-repeat: no-repeat;
	background-size: $sx $sy;
	-webkit-animation: $gradient-bt 15s ease infinite;
	-moz-animation: $gradient-bt 15s ease infinite;
	animation: $gradient-bt 15s ease infinite;
}

@mixin title-primary() {
	display: inline-flex;
	flex-direction: column;
	margin: 4.375rem 0 1.4rem;

	&::after {
		background: $primary-2;
		content: " ";
		height: 7px;
		margin-top: 1.3rem;
		width: 100%;
	}
}

@mixin product-line($bg-product, $bg-line) {
	h3 {
		display: inline-flex;
		flex-direction: column;
		margin: 4.375rem 0 0;

		&::after {
			@include animate-elm();
			background: $bg-product;
			content: " ";
			height: 1.5625rem;
			left: -0.9375rem;
			position: relative;
			top: -2.2rem;
			width: 100%;
			z-index: -2;
		}

		&.on-item {
			&::after  {
				-webkit-animation-name: bounce-in-right;
				animation-name: bounce-in-right;
			}
		}
	}

	p {
		border-top: 3px solid $bg-line;
		margin: 0;
		padding-top: 1rem;
	}
}

@mixin bg-onda-tb {
	animation: slide 60s linear infinite;
	background: url(#{$_image-path}/bkg/textura-ondas-rosas.svg) repeat center 15px;
	content: " ";
	display: block;
	height: 30px;
	left: 0;
	position: absolute;
	width: 1000%;
}

.full-width {
	.container-fluid {
		max-width: 100%;
		padding: 0;

		.row {
			width: 100%;
		}
	}
}

.intro-default {
	.content-media {
		p {
			align-items: center;
			background: $section-subtitle;
			border-radius: 50%;
			display: flex;
			height: 16.875rem;
			text-align: center;
			width: 16.875rem;
		}

		img {
			margin: auto;
		}
	}

	h2 {
		@include txt-font-25($section-subtitle, 800);
		font-family: $font-family-heading;
		letter-spacing: -0.69px;
		text-align: left;
		width: 27.5625rem;

		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			text-align: center;
		}
	}

	.content-text {
		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		p {
			@include p-default();
			letter-spacing: -0.5px;
			margin-left: 0;
			text-align: left;

			@media all and (min-width: map-get($grid-breakpoints, "sm") + 1) {
				width: 65%;
			}

			@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
				margin: auto;
			}

			&:first-of-type {
				background-image: url(#{$_image-path}/bkg/linea2-blue.svg) no-repeat left bottom;
				padding-bottom: 2.2rem;
			}

			&:last-of-type {
				margin: 0;
			}
		}
	}
}

.item-squared {
	ul {
		margin: auto;
	}

	.subtitle {
		@include txt-font-30($section-subtitle, 800);
		font-family: $font-family-heading;
		letter-spacing: -0.83px;
		margin: 5rem auto 4rem;
		text-align: center;
		text-transform: uppercase;
		width: 50%;
	}

	li {
		margin-bottom: 0.3rem;

		&::before {
			background: $brand-accent;
			content: " ";
			display: block;
			height: 10px;
			left: -1.4rem;
			margin-right: 1.3125rem;
			position: absolute;
			top: 0.4rem;
			width: 10px;
		}
	}

	h4 {
		@include txt-font-20($text-gral, 500);
		letter-spacing: -0.56px;
		line-height: 24px;
		position: relative;
		text-align: left;

		strong {
			margin-right: 0.5rem;
		}
	}
}

@mixin iconli() {
	background-size: cover;
	content: " ";
	display: block;
	height: 84px;
	margin-right: 2.5%;
	position: static;
	width: 83px;

	@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
		height: 70px;
		width: 70px;
	}
}

.item-check {
	ul {
		padding-top: 1.5rem;
	}

	.subtitle-line {
		margin-bottom: 4.1875rem;
	}

	img {
		object-fit: cover;
		width: 100%;
	}

	p {
		margin: 0;
	}

	li {
		padding-left: 2rem;

		&::before {
			background: url(#{$_image-path}/bkg/icon-check.svg) no-repeat center;
			content: " ";
			display: block;
			height: 2.375rem;
			left: -2rem;
			margin-right: 1.3125rem;
			position: absolute;
			top: 0;
			width: 2.375rem;
		}
	}

	h4 {
		@include txt-font-20($text-gral, 500);
		letter-spacing: -0.56px;
		line-height: 24px;
		margin-bottom: 3rem;
		position: relative;
		text-align: left;

		strong {
			margin-right: 0.5rem;
		}
	}
}

.sec-procesos-top .container-fluid,
.sec-equipos-top .container-fluid,
.sec-como-top .container-fluid,
.sec-contact-top .container-fluid{
	height: 506px !important;
	min-height: 506px !important;
	margin-bottom: 6rem;
	.inner-pages {
		position: relative;
		height: 506px !important;
		margin-bottom: 6rem;
		h2 {
			position: absolute;
			font-size: 41px !important;
			background: #000;
			padding: 2rem;
			left: 0;
			width: 60%;
			bottom: -4rem;
		}
	}
}

.yellow-title {
	display: flex;
	margin-top: -3rem;
	width: max-content;
	margin-left: 6rem;
	margin-right: auto;
	box-shadow: 3px -1px 15px 0px rgba(119, 119, 119, 0.49);
	-webkit-box-shadow: 3px -1px 15px 0px rgba(119, 119, 119, 0.49);
	-moz-box-shadow: 3px -1px 15px 0px rgba(119, 119, 119, 0.49);
	&.alt-tit {
		margin-right: 6rem;
		margin-left: auto;
	}
	h2 {
		background: $main-yellow;
		font-size: 30px;
		width: auto;
		padding: 0.53rem 1rem;
		min-height: 89px;
		margin-bottom: 0;
		z-index: 999;
		flex: 3;
	}
	img {
		height: 89px;
	}
}

.sec-equipos-top .container-fluid {
	.inner-pages {
		h2 {
			font-size: 32px !important;
		}
	}
}

.aviso-de-priv {
	h2 {
		margin: 11rem 0 4rem;
		font-weight: 800;
		font-size: 30px;
		text-align: center;
	}
}

.sec-aviso {
	.header .mnu a {
		color: $black;
	}
	.site-logo-link {
		background: url(../images/yellow-logo-black.svg) center/contain no-repeat;
	}
}

.sec-equipos {
	h2.quienes-tit {
		font-weight: normal !important;
	}
	p {
		b {
			font-weight: 800;
		}
	}
}

.quienes-tit {
	font-size: 30px;
	font-weight: 800;
}

.process-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 2rem;
	row-gap: 4rem;
	&.grid-somos {
		p {
			margin-top: 0 !important;
		}
	}
	div {
		display: flex;
		column-gap: 1rem;
		p {
			flex: 1;
			color: $black !important;
			span {
				font-weight: 800;
			}
		}
		img {
			width: 201px;
			height: 168px;
		}
	}
}

@mixin bg-top-page($imgbg) {
	$urlimg: "#{$_image-path}/bkg/#{$imgbg}";

	.container-fluid {
		background: url($urlimg) no-repeat right center;
		background-size: cover !important;
		background-position: right top;
		min-height: 655px;
		max-width: 100%;
		.content {
			max-width: 1100px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-left: auto;
			margin-right: auto;
			height: 655px;
			align-items: flex-end;
			text-align: right;
		}

		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			min-height: auto;
		}

		@media all and (max-width: map-get($grid-breakpoints, "xl") - 1) {
			background-size: 100%;
		}

		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
		}

		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			background-size: 50%;
		}

		@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
		}
	}

	.row {
		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			align-items: center;
			flex-direction: column;
		}
	}
}

.blog-header,
#sidebar {
	.search-wrapper {
		opacity: 1;
		visibility: visible;
		max-height: initial;
	}
}
.search-wrapper {
	opacity: 0;
	visibility: hidden;
	max-height: 0;
	transition: max-height 0.3s ease-out, opacity 0.8s;
	overflow: hidden;

	input:not([type="radio"]):not([type="checkbox"]) {
		border-bottom: 0;
		padding-left: 4rem;

		&::placeholder {
			opacity: 1; /* Firefox */
		}
	}
}

.show-search {
	opacity: 1;
	visibility: visible;
	max-height: 500px;
	transition: max-height 0.8s ease-in, opacity 0.8s;
}

.pro-list {
	display: flex;
	text-align: left;
	ul {
		li {
			margin-bottom: 3rem;
			background-image: url(#{$_image-path}/bkg/muve-bullet.svg);
			list-style: none;
			background-repeat: no-repeat;
			padding-left: 46px;
			background-position: center left;
			font-size: 20px;
			font-family: "Montserrat", sans-serif;
			&::before {
				content: none;
			}
		}
	}
}
.sec-contacto {
	.sec-title {
		&::after {
			background-color: transparent !important;
		}
	}
}

@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
	.quienes-text {
		display: block !important;
		overflow: hidden;
		.quien-desc .subtitle-line {
			margin-left: 0 !important;
		}
	}
	.sec-clientes .row {
		display: block;
		padding-left: 2rem;
		.col-lg-3 {
			max-width: 64%;
			margin-bottom: 4rem;
			margin-left: auto;
			margin-right: auto !important;
		}
	}
	.sec-promosiones .col-lg-4 h2.price,
	.sec-promosiones .col-lg-4 h3.price {
		display: block;
	}
	.sec-ventajas {
		.row {
			display: block;
			.col-lg-3 {
				max-width: 100%;
			}
		}
	}
}
.blog-content-item {
	input:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus) + label,
	select.invalid:not(:focus) + label,
	select:invalid:not(:focus) + label,
	textarea:placeholder-shown:not(:focus) + label {
		color: $color-primary;
		text-transform: uppercase;
		font-weight: 100;
	}
	input:not([type="radio"]):not([type="checkbox"]) + label,
	select + label,
	textarea + label {
		color: $color-primary;
		text-transform: uppercase;
		font-weight: 100;
	}
	input:-internal-autofill-selected {
		appearance: menulist-button;
		background-color: transparent !important;
	}

	input:not([type="radio"]):not([type="checkbox"]),
	select,
	textarea {
		color: #e0e0e0;
		border-bottom: 1px solid #e0e0e0;
	}

	.search-wrapper {
		form {
			width: 100%;
		}
	}
	h3 {
		font-size: 18px;
		font-weight: 700;
		color: #333;
	}
	form {
		width: 70%;
		& > div {
			&:first-of-type {
				margin-right: 2rem;
			}
			&:first-of-type,
			&:nth-child(2) {
				display: inline-block;
				width: 46.8%;
			}
		}
		.form-content {
			margin-top: 2rem;
		}
		.buttons {
			display: flex;
			justify-content: flex-end;
		}
	}
}
@media all and (max-width: map-get($grid-breakpoints, "md") + 1) {
	.sec-page-top h2 {
		margin-bottom: 2rem;
	}
	.sec-home .sec-conexiones,
	.sec-home .sec-internet,
	.sec-contacto {
		background-position: bottom !important;
		padding-bottom: 310px;
	}
	.quienes-text .quien-desc {
		padding: 0 !important;
		width: 100% !important;
	}
	.mivi-reg {
		display: block !important;
	}
	.mivi-reg .mivi-text {
		width: 100% !important;
		padding: 0 !important;
	}
	.sec-home .sec-promosiones .grid-valores {
		display: block !important;
	}
	.sec-internet,
	.sec-promosiones,
	.wrapper-footer {
		padding-left: 2.5625rem !important;
		padding-right: 2.5625rem !important;
	}
	.contact-form {
		width: 80% !important;
	}
	.footer .footer-sm {
		display: block !important;
		text-align: left !important;
	}
	.sec-somos {
		.sec-page-top h2 {
			margin-bottom: 0 !important;
			color: $black !important;
		}
	}
	.sec-serv .to-left {
		display: block !important;
		.sec-title {
			font-size: 26px !important;
		}
	}
	.sec-serv .to-left .the-text {
		width: 96% !important;
		display: block !important;
	}
	.sec-serv .to-left .the-text img {
		margin-top: 0rem;
	}
	.sec-serv.sec-servicio-left,
	.sec-serv.sec-servicio-two,
	.sec-serv.sec-servicio-three,
	.sec-serv.sec-servicio-four {
		background: none !important;
	}
	.sec-somos #ventajas {
		height: 162px !important;
	}
}
.mt-4 {
	margin-top: 4rem;
}

.foot-title {
	font-size: 20px;
	font-weight: 600;
	color: #fff;
}

a.button {
	font-size: 14px;
	width: 180px;
	height: 44px;
	text-transform: uppercase;
	background: $main-yellow;
	color: $white;
	padding: 10px 18px;
	font-family: "Montserrat", sans-serif;
	&:hover {
		border: 2px solid $main-yellow;
	}
}
@media (min-width: 601px) and (max-width: 900px){
	#home-slider .container-fluid .swiper-slide{
		background-size: cover !important;
	}
}
@media (min-width: 320px) and (max-width: 600px) {
	.slide1{
		.swiper-slide{
			background-image:url('/user/themes/yellowcoating/images/banners/yellow-home-m.png') !important;
		}
	}
	.slide2{
		.swiper-slide{
			background-image:url('/user/themes/yellowcoating/images/banners/yellow-home-2-m.png') !important;
		}
	}
	.slide3{
		.swiper-slide{
			background-image:url('/user/themes/yellowcoating/images/banners/yellow-home-3-m.png') !important;
		}
	}
	.slide4{
		.swiper-slide{
			background-image:url('/user/themes/yellowcoating/images/banners/yellow-home-4-m.png') !important;
		}
	}
	.slide5{
		.swiper-slide{
			background-image:url('/user/themes/yellowcoating/images/banners/yellow-home-5-m.png') !important;
		}
	}
	.contact-form {
		width: 90% !important;
	  }
	.sec-contact-top .container-fluid {
		min-height: 506px !important;
		background-position: left !important;
	  }
	.process-grid div img {
		width: 100%;
		height: auto;
	  }
	.contact-background{
		display: none;
	}
	.right-header {
		width: auto !important;
		margin-top: 0 !important;
		display: contents;
	}
	.sec-page-top h2 {
		font-size: 30px !important;
		margin-top: 0 !important;
		text-shadow: 1px 1px 4px rgba(150, 150, 150, 1);
	}
	.site-logo-link {
		background: url(../images/yellow-logo-black.svg) center/contain no-repeat;
	}
	.header-bntns {
		flex-direction: column;
		justify-content: center;
		width: 100%;
		.button {
			margin-left: 0 !important;
		}
	}
	.quienes-info {
		display: none !important;
	}
	.sec-home .grid-procesos {
		display: block !important;
	}
	.sec-home .grid-procesos div h1 {
		font-size: 25px !important;
	}
	.footer .footer-grid {
		display: block !important;
	}
	.foot-firt-trg .foot-line-twoe {
		display: block !important;
		p {
			margin-bottom: 1rem !important;
		}
	}
	.sec-como-top .container-fluid .inner-pages h2,
	.sec-equipos-top .container-fluid .inner-pages h2,
	.sec-procesos-top .container-fluid .inner-pages h2,
	.sec-contact-top .container-fluid .inner-pages h2 {
		position: inherit !important;
		width: 100% !important;
		padding: 0 !important;
		background: transparent;
	}
	.yellow-title {
		flex-direction: column;
		margin-top: 0 !important;
		margin-left: 0 !important;
		background: $main-yellow;
	}
	.yellow-title {
		width: 100% !important;
		h2 {
			font-size: 26px !important;
		}
	}
	.process-grid {
		display: block !important;
		div {
			display: block !important;
		}
	}
	.content-form {
		padding: 0 !important;
		display: block !important;
		width: 90% !important;
		margin: auto !important;
	}
	.sec-cont .sec-contact-top {
		min-height: auto !important;
	}
	.header .container-fluid {
		border-bottom: 0 !important;
	}
	.subheader .content {
		justify-content: center !important;
	}
	.sec-page-top h3 {
		color: $black !important;
	}
	.header .mnu {
		border-right: 0 !important;
		margin-right: 0 !important;
	}
	.footer-sm:last-of-type {
		margin-bottom: 2rem;
	}
	.header .footer-sm .ico {
		margin-right: 1rem;
	}
	.soluciones-reg .solu-title {
		width: 223px !important;
	}
	.soluciones-reg .sec-title {
		font-size: 30px !important;
	}
	.soluciones-reg .soluciones-icons {
		display: block !important;
	}
	.sec-promosiones .sec-title {
		font-size: 20px !important;
	}
	#contact-form.row {
		margin-left: 1rem !important;
		margin-right: 0 !important;
		margin-top: 3rem;
	}
	footer .foot-line-one {
		flex-direction: column;
	}
	.foot-firt-trg {
		flex-direction: column;
	}
	.sec-como-top .container-fluid .content h2, .sec-page-top h2 {
		color: $white !important;
		background: transparent;
	}
	.sec-como-top .container-fluid .content p {
		color: $black !important;
	}
	.sec-como-funciona .sec-clientes {
		padding: 2rem !important;
	}
	.content-alt {
		width: auto !important;
	}
	.sec-internet,
	.sec-promosiones,
	.wrapper-footer {
		padding-left: 1rem !important;
		padding-right: 1rem !important;
	}
	.sec-como-funciona .sec-internet.alt .pro-list .content-alt {
		display: block !important;
	}
	.sec-como-funciona .sec-internet.alt .pro-list {
		background: transparent !important;
	}
	.fundadoras .co-fun-foto img {
		width: 40%;
	}
	.fundadoras .content-alt.mivision div h4 {
		font-size: 20px !important;
	}
	section .sec-title {
		font-size: 30px !important;
	}
	.swiper-slide {
		.container-fluid {
			width: 90% !important;
			margin: 0 auto;
			h2 {
				font-size: 32px !important;
				width: auto !important;
			}
		}
	}
	#home-slider .container-fluid .swiper-slide {
		background-size: cover !important;
		background-position: center !important;
	}
	#home-slider .container-fluid .swiper-slide .container-fluid {
		justify-content: center !important;
	}
	.whats #dk-whatsapp {
		display: none;
	}
	.whats #mb-whatsapp {
		display: block !important;
		img{
			width: 40px !important;
		}
	}
	input:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus) + label,
	select.invalid:not(:focus) + label,
	select:invalid:not(:focus) + label,
	textarea:placeholder-shown:not(:focus) + label {	
		font-size: 16px !important;
	}
	.sec-como-top .container-fluid,
	.sec-equipos-top .container-fluid,
	.sec-procesos-top .container-fluid {
		background-size: cover !important;
	}
	.header {
		position: initial !important;
	}
	.sec-home .sec-conexiones {
		padding: 2rem 0 !important;
		img {
			margin-bottom: 1rem;
		}
	}
	.sec-promosiones {
		padding: 0rem 0 1rem !important;
	}
	.sec-conexiones {
		img {
			margin: 1rem 0;
		}
	}
	
	.sec-como-top .container-fluid,
	.sec-equipos-top .container-fluid,
	.sec-procesos-top .container-fluid {
		margin-bottom: 0rem !important;
	}
	.accordion-button {
		margin-left: auto;
		display: block;
	}
	#ventajas {
		img {
			margin-bottom: 2rem;
		}
	}
	.sec-como-funciona .sec-clientes p {
		margin-top: 1rem !important;
	  }
	  .sec-internet p span{
		font-size: 25px !important;
		margin-top: 1rem;
	  }
	  .contact-top p {
		margin: 2rem 0 !important;
	  }
	  .sec-contacto, .sec-home .sec-conexiones {
		padding-bottom: 0 !important;
	  }
	  .sec-home .sec-internet {
		padding: 1rem 0 !important;
	  }
	  #ventajas .accordion-button {
		  order: 3;
		img {
			margin-bottom: 0;
		  }
	  }
	  #accordionExample img{
		  margin-bottom: 0;
	  }
	  .footer .footer-grid div a {
		margin-bottom: 2rem !important; 
		display: block !important;
	  }
	  .footer .footer-grid div p {
		min-height: auto !important;
	}
}

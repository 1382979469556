.sec-home-top,
.sec-como-top,
.sec-somos-top,
.sec-contact-main {
	p {
		opacity: 1;
		line-height: 25px;
	}
}

.sec-home-top {
	@include bg-top-page("yellow-home.png");
}
.sec-procesos-top{
	@include bg-top-page("yellow-somos-pg.jpg");
}
.sec-equipos-top{
	@include bg-top-page("yellow-equipos-pg.png");
}
.sec-como-top {
	@include bg-top-page("yellow-nosotros-pg.jpg");
}

.sec-osomos-top {
	@include bg-top-page("int-nosotros.jpg");
}

.sec-contact-main {
	@include bg-top-page("ofera-contact.jpg");
	.container-fluid {
		background-position: center !important;
	}
}

.sec-contact-top {
	@include bg-top-page("yellow-contact.png");
	.container-fluid{
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

.custom-col {
	max-width: 1100px;
	flex: 1;
}

// ****************************
// conexiones
// ****************************
.sec-conexiones {
	margin-top: 4.3rem;
	overflow: hidden;
	padding: 0 2.5625rem 0;

	.sec-subtitle {
		@include txt-font-40($section-subtitle, 40px);
		font-weight: 800;
		letter-spacing: -1.11px;
		margin-bottom: 4.7rem;
		text-align: center;
		text-transform: uppercase;
	}
	.us-desc {
		text-align: left;
		position: absolute;
		top: 410px;
		padding: 0 10px;
		.subtitle-line {
			margin-bottom: 0;
			padding-bottom: 0;
			line-height: 0.9;
		}
		p {
			font-size: 21px;
		}
		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			position: initial;
		}
	}
	.us-icons {
		margin-top: 120px;
		.d-flex {
			div {
				@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
					margin-top: 2rem;
				}
			}
		}
	}
}

.quienes-text {
	display: flex;
	margin-left: auto;
	margin-right: auto;
	column-gap: 1rem;

	.quien-desc {
		width: 50%;
		padding: 2rem 0 0;
		flex: 1;
		h2{
			span{
				color: $main-yellow;
			}
		}
	}
	.quien-aux {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
}

.quienes-info {
	display: flex;
	column-gap: 3rem;
	background: $white;
	padding: 2.4rem 2.85rem;
	box-shadow: 3px -1px 15px 0px rgba(119, 119, 119, 0.49);
	-webkit-box-shadow: 3px -1px 15px 0px rgba(119, 119, 119, 0.49);
	-moz-box-shadow: 3px -1px 15px 0px rgba(119, 119, 119, 0.49);
	width: fit-content;
    margin-top: 4rem;
	margin-bottom: 1rem;
	div {
		align-items: center;
		display: flex;
		column-gap: 1rem;
	}
	p {
		margin: 0;
		font-size: 14px;
		line-height: 1.2;
		small {
			font-size: 16px;
		}
	}
}

.right-header {
	width: 532px;
}

.mivi-reg {
	.mivi-text {
		width: 50%;
		padding: 2rem 0;
	}
}

.wrapper-animation {
	justify-content: center;
	margin: 0 auto 3.7rem auto;

	.d-flex {
		justify-content: center;
		position: relative;
		align-items: flex-end;
		justify-content: space-around;
	}

	h5 {
		line-height: 1;
		font-weight: 600;
		font-size: 38px;
		letter-spacing: -0.92px;
		order: 2;
		padding-top: 1rem;
		text-align: center;
		text-transform: uppercase;
	}
}

.mv-and {
	@include animate-elm();
	bottom: 2000px;
	display: flex;
	flex-direction: column;
	position: relative;
	flex: 1;

	svg {
		height: 11.5625rem !important;
		margin: auto;
		width: 11.5625rem !important;
	}

	&:nth-child(1) {
		padding-right: 3.3rem;

		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			padding-right: 0;
		}
	}

	&:nth-child(2) {
		-webkit-animation-delay: 0.7s;
		animation-delay: 0.7s;
		padding-right: 3.3rem;

		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			margin: 2rem auto;
			padding-right: 0;
		}
	}

	&:nth-child(3) {
		-webkit-animation-delay: 1.4s;
		animation-delay: 1.4s;
	}

	&.on-item {
		-webkit-animation-name: bounce-in-up;
		animation-name: bounce-in-up;
		bottom: 0;
		position: relative;

		&:nth-child(1) {
			&::after {
				-webkit-animation: 1.3s ease 0s normal forwards 1 fadein;
				animation: 1.3s ease 0s normal forwards 1 fadein;
				opacity: 1;
			}
		}

		&:nth-child(2) {
			&::after {
				-webkit-animation: 1.7s ease 0s normal forwards 1 fadein;
				animation: 1.7s ease 0s normal forwards 1 fadein;
				opacity: 1;
			}
		}
	}
}

// ****************************
// sec-servicios
// ****************************
.sec-servicios {
	overflow: hidden;
	background: $white;

	.col-lg-3 {
		min-height: 360px;
		display: flex;
		align-items: flex-end;
		padding: 1rem;
		&.h-one {
			background: url(#{$_image-path}/bkg/ofera-back-1.jpg) no-repeat;
			background-size: cover;
		}
		&.h-two {
			background: url(#{$_image-path}/bkg/ofera-back-2.jpg) no-repeat;
			background-size: cover;
		}
		&.h-three {
			background: url(#{$_image-path}/bkg/ofera-back-3.jpg) no-repeat;
			background-size: cover;
		}
		&.h-four {
			background: url(#{$_image-path}/bkg/ofera-back-4.jpg) no-repeat;
			background-size: cover;
		}
		p {
			color: $white;
			text-align: left;
			font-size: $font-size-25;
			font-weight: 600;
		}
		a {
			color: $white;
			text-align: right;
			width: 100%;
			display: block;
			font-size: $font-size-20;
			font-weight: 100;
			padding-right: 3rem;
			background: url(#{$_image-path}/bkg/arrow-r.png) no-repeat right center;
		}
	}

	.col-lg-6.back-img {
		background: url(#{$_image-path}/bkg/fedi-servicios.jpg) no-repeat center bottom;
		background-size: cover;
	}

	.container-fluid {
		max-width: 100%;
		margin: 0;
		padding: 0;
		width: 100%;
	}

	.sec-subtitle {
		@include txt-font-40($section-subtitle, 40px);
		font-weight: 800;
		letter-spacing: -1.11px;
		text-align: center;
		text-transform: uppercase;
	}

	.row {
		padding-top: 1.8rem;
		position: relative;
	}

	h3 {
		@include txt-font-30($section-subtitle, 800);
		background: url(#{$_image-path}/bkg/ondas-title-pink.svg) no-repeat center bottom;
		display: inline-flex;
		letter-spacing: -0.83px;
		line-height: 38px;
		margin-bottom: 0.8rem;
		padding-bottom: 1.28rem;
		text-align: center;
		text-transform: uppercase;
	}

	p {
		@include txt-font-18($text-gral, 500);
		letter-spacing: -0.5px;
		line-height: 21px;
		margin: 0 auto 0.5rem;
		position: relative;
		text-align: center;
		z-index: 5;
	}

	.button {
		margin-top: 1.5rem;
	}
}

// ****************************
// sec-testimonios
// ****************************
.sec-testimonios {
	background: $bg-testimonios;
	height: 456px;
	margin-top: 4.5rem;
	padding-top: 4.375rem;
	position: relative;

	&::before {
		@include circle-lateral();
		left: 0;
		transform: translate(-50%, -50%); 
	}

	&::after {
		@include circle-lateral();
		right: 0;
		transform: translate(50%, -50%);
	}

	.swiper-pagination {
		bottom: 90px;
		pointer-events: fill;
	}

	.container-fluid {
		height: 100%;
	}

	.swiper-button-prev,
	.swiper-button-next {
		display: none;
	}

	.swiper-container {
		height: 456px;
		pointer-events: none;
	}

	.swiper-pagination-bullet {
		background: $bg-pink-1;
		border: 0;
		height: 0.7rem;
		margin: 0.3rem;
		opacity: 0.8;
		width: 0.7rem;

		&.swiper-pagination-bullet-active  {
			opacity: 1;
		}
	}

	h3 {
		@include txt-font-50($brand-primary, $font-size-50);
		@include line-subtitle();
		display: inline-flex;
		font-weight: 900;
		letter-spacing: -1.39px;
		line-height: 63px;
		margin: 0 auto 2.5rem;
		text-align: center;
		text-transform: uppercase;
		word-break: break-all;
	}

	p {
		@include txt-font-16($text-gral);
		border-bottom: solid 1px $brand-accent;
		font-weight: 500;
		letter-spacing: -0.47px;
		margin-bottom: 1.3rem;
		padding-bottom: 1.5625rem;
		width: 60.5%;

		@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
			width: 100%;
		}
	}

	h6 {
		@include txt-font-16($text-gral);
		font-style: italic;
	}
}

.sec-como-funciona {
	.sec-clientes {
		padding: 4rem 0;
		min-height: 12rem;
		min-height: 540px;
		margin: 0;
		p {
			font-size: 20px;
			text-align: left;
			line-height: 1.2;
			margin-top: 2rem;
		}
	}

	.sec-internet.alt {
		.sec-title {
			color: $light-black;
			font-weight: normal;
			display: inline-flex;
			margin-bottom: 3rem;
			font-family: 'Montserrat', sans-serif;
		}
		.pro-list {
			margin: 4rem 0;
			min-height: 12rem;
			background: url(#{$_image-path}/bkg/muve-obj.jpg) left/no-repeat;
			background-size: 45%;
			min-height: 540px;
			.content-alt {
				display: flex;
				max-width: 1100px;
				width: 1100px;
				margin: auto;
				.quien-aux,
				.quien-desc {
					flex: 1;
					ul {
						padding-left: 0;
					}
				}
			}
		}
	}
}

.content-alt {
	max-width: 1100px;
	width: 1100px;
	margin: auto;
	&.mivision {
		display: flex;
		column-gap: 2rem;
		align-items: flex-start;
		div {
			flex: 1;
			h4 {
				font-size: 30px;
				text-transform: uppercase;
				color: $light-black;
				font-family: 'Montserrat', sans-serif;
			}
		}
	}
}

.more-link {
	color: $black;
	font-size: 18px;
	text-transform: uppercase;
	&:hover {
		text-decoration: none;
		color: $main-yellow !important;
	}
}

.sec-home {
	.sec-conexiones {
		padding: 5rem 0;
		min-height: 12rem;
		background: $white;
		margin: 0;
	}

	.sec-internet {
		padding: 6rem 0;
		min-height: 12rem;
		background: url(#{$_image-path}/bkg/yellow-quees.png) left/no-repeat;
		background-size: cover;
		margin: 0;
		.more-link {
			color: $white;
		}
	}

	.sec-page-top {
		padding: 0;
	}

	.grid-procesos {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		div {
			padding: 8rem 1rem 1rem;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			box-shadow: 1px -31px 62px -9px rgba(0, 0, 0, 0.81) inset;
			-webkit-box-shadow: 1px -31px 62px -9px rgba(0, 0, 0, 0.81) inset;
			-moz-box-shadow: 1px -31px 62px -9px rgba(0, 0, 0, 0.81) inset;
			padding: 11rem 1rem 1rem;
			background-repeat: no-repeat !important;
			background-position: center !important; 
			background-size: cover !important;
			h1 {
				font-size: 32px;
				color: $main-yellow;
				font-weight: 800;
			}
		}
	}

	.sec-promosiones {
		.grid-valores {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			div {
				background: $white-2;
				padding: 8rem 1rem 1rem;
				span {
					img {
						max-height: 12px;
						min-height: 12px;
					}
				}
				img {
					max-height: 94px;
					min-height: 94px;
				}
				&.grid-odd {
					background: $main-yellow;
				}
			}
			h4 {
				text-transform: uppercase;
				font-size: 30px;
				color: $black;
				font-weight: 100;
				margin: 1rem 0 1.5rem;
				font-family: 'Montserrat', sans-serif;
			}
			p {
				font-size: 20px;
			}
		}
	}
}

.soluciones-reg {
	text-align: center;
	.solu-title {
		width: 673px;
		margin: auto;
	}
	.sec-title {
		font-size: 36px;
		text-transform: uppercase;
		font-weight: 400;
		color: $white;
		font-family: 'Montserrat', sans-serif;
	}
	.soluciones-icons {
		display: flex;
		justify-content: space-between;
		margin-top: 6rem;
		align-items: flex-end;
		p {
			color: $color-primary;
			text-transform: uppercase;
			font-size: 24px;
			font-family: 'Montserrat', sans-serif;
			margin-top: 3rem;
		}
	}
}

.accordion-button{
    transform: none;
    &:hover, &:focus{
        transform: none;
    }
}

.whats {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	padding: 1rem 0;
	position: fixed;
	right: 2rem;
	bottom: 2rem;
	z-index: 999;
}
.whats img {
	width: 80px;
}
